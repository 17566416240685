const staticMap = require('../static-file-map.json')
const versionator = require('versionator')
const mappedVersion = versionator.createMapped(staticMap)

if (typeof SITE_URL === 'undefined') {
  module.exports = mappedVersion.versionPath
} else {
  // This is here for the mobile app
  module.exports = (url) => SITE_URL + url
}
