import debug from 'debug'
import initRealtime from '../../../common/matchday-live/realtime'
import initCommentary from './commentary/init'
import initMatchScore from './match-score/init'
import initJumbotron from './jumbotron/init'
import initStats from './stats/init'

const config = JSON.parse(
  document.querySelector('body').getAttribute('data-browser-config')
)

const log = debug('match-day-live:log')
const init = (serviceLocator) => {
  log('Initializing match day live')

  document.addEventListener('DOMContentLoaded', () => {
    const match = window.__entity
    if (!match || match.isLive !== true) return
    const primus = initRealtime(config)
    const commentaryNodes = document.querySelectorAll('.js-commentary')
    const matchScoreNodes = document.querySelectorAll('.js-match-score')
    const jumbotronNodes = document.querySelectorAll('.js-jumbotron')
    const matchStatsNodes = document.querySelectorAll('.js-match-stats')
    const playerStatsNodes = document.querySelectorAll('.js-player-stats')

    primus.on('open', () => {
      log('emit matchDayLive')
      primus.send('matchDayLive', { _id: match._id })
      primus.send('liveStats')
    })

    if (commentaryNodes.length === 0) {
      return log('No commentary found.')
    } else {
      initCommentary(commentaryNodes, primus, match.__commentary)
    }

    if (matchScoreNodes.length === 0) {
      return log('No match scores found.')
    } else {
      initMatchScore(matchScoreNodes, primus, match)
    }

    if (jumbotronNodes.length === 0) {
      return log('No jumbotron found.')
    } else {
      initJumbotron(jumbotronNodes, primus, match)
    }

    if (matchStatsNodes.length === 0) {
      return log('No match stats found')
    } else {
      initStats(matchStatsNodes, primus, match, 'match')
    }

    if (playerStatsNodes.length === 0) {
      return log('No player stats found')
    } else {
      initStats(playerStatsNodes, primus, match, 'player')
    }

    primus.on('matchUpdate', () => {
      log('matchUpdate')
      window.location.reload()
    })
  })
}

export default init
