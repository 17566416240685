import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'
import Slide from '../Slide'

const PreOrder = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundType="svg"
      backgroundImage="StripesVertical"
      backgroundColor="green"
      plxEnabled={plxEnabled}
      isFullHeight={false}
      noHeightLimit
      isBackgroundExtended
      id="slidepreorder"
      className="ns-pre-order--relative"
    >
      <div className="ns-pre-order">
        <h2 className="ns-slide__heading ns-slide__heading--red">Pre-order</h2>
        <div className="ns-pre-order__grid">
          <div className="grid grid--flex grid--spacious">
            <div className="grid__item one-half desktop-one-quarter">
              <PlxComponent
                parallaxData={config.fg.box[0]}
                className="ns-pre-order__item ns-pre-order__item--green"
              >
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/home-closeup1.jpg`
                  )}
                />
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/home-closeup2.jpg`
                  )}
                />
              </PlxComponent>
            </div>
            <div className="grid__item one-half desktop-one-quarter">
              <PlxComponent
                parallaxData={config.fg.box[1]}
                className="ns-pre-order__item ns-pre-order__item--tall ns-pre-order__item--red"
              >
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/home-replica.png`
                  )}
                />
              </PlxComponent>
            </div>
            <div className="grid__item one-half desktop-one-quarter">
              <PlxComponent
                parallaxData={config.fg.box[2]}
                className="ns-pre-order__item ns-pre-order__item--tall ns-pre-order__item--lime"
              >
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/change-replica.png`
                  )}
                />
              </PlxComponent>
            </div>
            <div className="grid__item one-half desktop-one-quarter">
              <PlxComponent
                parallaxData={config.fg.box[3]}
                className="ns-pre-order__item ns-pre-order__item--pink"
              >
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/change-closeup1.jpg`
                  )}
                />
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/change-closeup2.jpg`
                  )}
                />
              </PlxComponent>
            </div>
          </div>
        </div>
        <div className="ns-slide__footer">
          <div>
            <span>Pre-order from July 27</span>
          </div>
        </div>
      </div>
    </Slide>
  )
}

PreOrder.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default PreOrder
