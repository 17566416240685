import ArrowDown from './svg/ArrowDown'
import ArrowLeft from './svg/ArrowLeft'
import ArrowRight from './svg/ArrowRight'
import ArrowUp from './svg/ArrowUp'
import AutoRenew from './svg/AutoRenew'
import Ball from './svg/Ball'
import BallWhite from './svg/BallWhite'
import TNTSport from './svg/TNTSport'
import ClusterMarker from './svg/ClusterMarker'
import Comment from './svg/Comment'
import Conversion from './svg/Conversion'
import DropGoal from './svg/DropGoal'
import External from './svg/External'
import Facebook from './svg/Facebook'
import FlagEngland from './svg/FlagEngland'
import FlagScotland from './svg/FlagScotland'
import FlagWales from './svg/FlagWales'
import Falling from './svg/Falling'
import Hamburger from './svg/Hamburger'
import HomeMarker from './svg/HomeMarker'
import Instagram from './svg/Instagram'
import Itv from './svg/Itv'
import LinkedIn from './svg/LinkedIn'
import Marker from './svg/Marker'
import Minus from './svg/Minus'
import Penalty from './svg/Penalty'
import Plus from './svg/Plus'
import PrtvLive from './svg/PrtvLive'
import Quote from './svg/Quote'
import RedCard from './svg/RedCard'
import Rising from './svg/Rising'
import Search from './svg/Search'
import SentOff from './svg/SentOff'
import SinBin from './svg/SinBin'
import Shop from './svg/Shop'
import SkySports from './svg/SkySports'
import Star from './svg/Star'
import Substitution from './svg/Substitution'
import Tick from './svg/Tick'
import TikTok from './svg/TikTok'
import TriangleRight from './svg/TriangleRight'
import Twitter from './svg/Twitter'
import Whistle from './svg/Whistle'
import YellowCard from './svg/YellowCard'
import Youtube from './svg/Youtube'
import ArrowDownCircle from './svg/ArrowDownCricle'

import StripesVertical from './svg/StripesVertical'
import StripesHorizontal from './svg/StripesHorizontal'
import TigersT from './svg/TigersT'
import Greatness1 from './svg/Greatness1'
import Greatness2 from './svg/Greatness2'
import Greatness3 from './svg/Greatness3'

const svgs = {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  AutoRenew,
  Ball,
  BallWhite,
  TNTSport,
  ClusterMarker,
  Comment,
  Conversion,
  DropGoal,
  External,
  Facebook,
  Falling,
  FlagEngland,
  FlagScotland,
  FlagWales,
  Hamburger,
  HomeMarker,
  Instagram,
  Itv,
  LinkedIn,
  Marker,
  Minus,
  Penalty,
  Plus,
  PrtvLive,
  Quote,
  RedCard,
  Rising,
  Search,
  SentOff,
  SinBin,
  Shop,
  SkySports,
  Star,
  Substitution,
  Tick,
  TikTok,
  TriangleRight,
  Twitter,
  Whistle,
  YellowCard,
  Youtube,
  StripesHorizontal,
  StripesVertical,
  TigersT,
  Greatness1,
  Greatness2,
  Greatness3,
  ArrowDownCircle,
}

export default svgs
