import React from 'react'
import PropTypes from 'prop-types'

const ArticleHeader = ({ category, title, meta }) => {
  return (
    <header className="article-header">
      {category && <div className="category">{category}</div>}
      <h2 className="h1 article-title">{title}</h2>
      {meta && <div className="article-meta">{meta}</div>}
    </header>
  )
}

ArticleHeader.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string.isRequired,
  meta: PropTypes.string,
}

export default ArticleHeader
