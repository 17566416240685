import Swiper from 'swiper'
const debug = require('debug')('carousel')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initSwipers)
  document.addEventListener('deviceready', initSwipers)
}

const initSwipers = () => {
  document
    .querySelectorAll('.js-carousel:not(.is-initialised)')
    .forEach((carousel) => {
      const prev = carousel.querySelector('.js-carousel-prev')
      const next = carousel.querySelector('.js-carousel-next')
      const counter = carousel.querySelector('.js-carousel-counter')

      const swiperInstance = new Swiper(carousel, {
        speed: 200,
        loop: true,
        autoHeight: true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
      })

      updateCounter(swiperInstance, counter)

      swiperInstance.params.onSlideChangeStart = () => {
        updateCounter(swiperInstance, counter)
      }

      prev.addEventListener('click', () => swiperInstance.slidePrev())
      next.addEventListener('click', () => swiperInstance.slideNext())

      carousel.classList.add('is-initialised')
    })
}

const updateCounter = (swiper, counter) => {
  const num = swiper.realIndex + 1 || 1
  const total = swiper.slides.length - 2
  counter.innerHTML = `${num} / ${total}`
}

export { init, initSwipers }
