import throttle from 'lodash.throttle'
const debug = require('debug')('Heritage')

export default () => {
  debug('Init')
  const heritageContainer = document.querySelector('.js-heritage')
  if (!heritageContainer) return false
  const heritageItems = [
    ...heritageContainer.querySelectorAll('.js-heritage-item'),
  ]
  const progressContainer = heritageContainer.querySelector(
    '.heritage__progress'
  )
  const buttons = [ ...heritageContainer.querySelectorAll('.js-switch-state') ]
  const returnButton = document.querySelector('.heritage__return-button')
  let previousItem = 0
  let currentItem = 0

  buttons.forEach((button) => {
    button.addEventListener('click', () =>
      heritageContainer.classList.toggle('heritage--is-detailed')
    )
  })

  const getCurrentItem = () => {
    let flag = true
    let index
    heritageItems.find((item, i) => {
      const itemPosition =
        item.getBoundingClientRect().bottom -
        item.getBoundingClientRect().height / 2 +
        window.scrollY

      if (window.scrollY < itemPosition && flag) {
        index = i
        flag = false
      }
    })

    return index
  }

  const scrollFunc = () => {
    currentItem = getCurrentItem()

    if (typeof currentItem !== 'undefined' && currentItem !== previousItem) {
      const activeElement = progressContainer.querySelector(
        '.heritage__progress-item--is-active'
      )
      if (activeElement)
        activeElement.classList.remove('heritage__progress-item--is-active')
      progressContainer.childNodes[currentItem + 1].classList.add(
        'heritage__progress-item--is-active'
      )
      const heritageIndex =
        currentItem + 1 >= heritageItems.length ? currentItem : currentItem + 1
      const location = heritageItems[heritageIndex].firstChild.name
      returnButton.href = `#${location}`
    }

    previousItem = currentItem
  }

  window.addEventListener('scroll', throttle(scrollFunc, 160))
}
