import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const MatchStatsRow = ({
  isDouble,
  label,
  homeValue,
  awayValue,
  homePreValueText,
  awayPreValueText,
  isPercentage,
}) => {
  // Territory and percentage can come through as 0% for both teams from opta as they dont always track it
  if (isPercentage && homeValue === 0 && awayValue === 0) return null

  let styleHome = {}
  let styleAway = {}

  if (homeValue === 0 && awayValue === 0) {
    styleHome.width = '50%'
    styleAway.width = '50%'
  } else if (!isDouble && !isPercentage) {
    styleHome.width = `${(homeValue / (homeValue + awayValue)) * 100}%`
  } else {
    styleHome.width = `${homeValue * 100}%`
    styleAway.width = `${awayValue * 100}%`
  }

  const formatText = (value) =>
    isPercentage ? `${Math.round(value * 100)}%` : value
  let homeText = formatText(homeValue)
  let awayText = formatText(awayValue)

  if (homePreValueText) {
    homeText = `${homePreValueText} ${homeText}`
  }
  if (awayPreValueText) {
    awayText = `${awayPreValueText} ${awayText}`
  }

  return (
    <div
      className={classNames('match-stats__row', {
        'match-stats__row--double': isDouble,
      })}
    >
      <div className="match-stats__row-heading">{label}</div>
      <div className="match-stats__value match-stats__value--home">
        {homeText}
      </div>
      {isDouble ? (
        <div className="match-stats__double-bar-wrapper auto-justify">
          <div className="match-stats__bar match-stats__bar--small">
            <div className="match-stats__bar-filled" style={styleHome} />
          </div>
          <div className="match-stats__bar match-stats__bar--small">
            <div className="match-stats__bar-filled" style={styleAway} />
          </div>
        </div>
      ) : (
        <div className="match-stats__bar">
          <div className="match-stats__bar-filled" style={styleHome} />
        </div>
      )}

      <div className="match-stats__value match-stats__value--away">
        {awayText}
      </div>
    </div>
  )
}

MatchStatsRow.propTypes = {
  isDouble: PropTypes.bool,
  label: PropTypes.string.isRequired,
  homeValue: PropTypes.number.isRequired,
  awayValue: PropTypes.number.isRequired,
  homePreValueText: PropTypes.string,
  awayPreValueText: PropTypes.string,
  isPercentage: PropTypes.bool,
}

export default MatchStatsRow
