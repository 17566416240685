import React from "react"

const ItvSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 250 125.12">
    <path
      d="M181 349.86c0-9 6.4-16.42 16.68-16.42 10.82 0 16.63 7.75 16.63 16.86 0 10-7.34 16.09-16.55 16.09-10.03 0-16.76-6.85-16.76-16.53ZM181.09 380.1a2.41 2.41 0 0 1 2.58-2.64h28.1a2.47 2.47 0 0 1 2.46 2.62v43.07c0 7.35 4.18 15.89 17.09 15.89 12.57 0 19.07-7.82 19.07-23.79v-3.36l13.35 25.4 1.72 8.61s-13.09 12.66-36.82 12.66c-11.78 0-47.55-1.93-47.55-41.07Z"
      transform="translate(-181 -333.44)"
      style={{
        fill: "#00beca",
      }}
    />
    <path
      d="m265.46 445.9 13.34-34.44 4.31-4.3v6c0 14.5 7 25.65 25.23 25.65 16.59 0 28.27-9.4 28.27-24a28.23 28.23 0 0 0-3.59-13.3l18.82 9.4 1.65 22.9s-12.49 24.62-47.86 24.62c-27.63 0-40.17-12.53-40.17-12.53Z"
      transform="translate(-181 -333.44)"
      style={{
        fill: "#8c7b11",
      }}
    />
    <path d="M84.46 112.46a43.45 43.45 0 0 1-15.07-33V17.75c0-4.95 3.2-9.25 16.54-9.25 14.58 0 16.18 5.67 16.18 9.93v25.59h25.83c1.86 0 2.58 1.08 2.58 2.81v15.26c0 1.45-.59 2.59-2.28 2.59h-17.09a30 30 0 0 1-9-1.72v10.33c-.04 12.88-3.37 26.04-17.69 39.17Z" />
    <path
      d="m353.49 433.85 4.1-45.06 1.86-7.84s8.18 11.93 29 50.32c13.24-24.08 21.68-52.85 21.68-52.85.6-2 2.17-2.45 4.44-1.85l14.3 4.42c1.73.62 2.67 1.31 1.7 4.53-11.9 34.6-30.51 63.15-30.51 63.15-3.59 5.31-7.24 9.82-19.34 9.82-9.76 0-13.16-3.32-16.56-8.4Z"
      transform="translate(-181 -333.44)"
      style={{
        fill: "#ffab00",
      }}
    />
    <path
      d="m353.49 433.85-22.28-35.12c-1.34-2.5-2.36-4-2.36-7 0-4.37 2.29-7.53 10-12.35a29.12 29.12 0 0 1 12.51-3.48c6.55 0 11.44 6.78 11.44 21-.01 23.1-9.31 36.95-9.31 36.95Z"
      transform="translate(-181 -333.44)"
      style={{
        fill: "#c80063",
      }}
    />
  </svg>
)

export default ItvSvg
