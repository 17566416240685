import React from 'react'
import PropTypes from 'prop-types'
import VideoComponent from '../../../../layout/component/video/component/Video'
import Quote from '../../../../layout/component/quote/component/Quote'
// import Plx from 'react-plx'
import Slide from '../Slide'

const GreatnessContent = ({ config }) => {
  return (
    <Slide
      isShort
      isBorderless
      isFullHeight={false}
      isSticky={false}
      config={config}
    >
      <div className="ns-greatness__content">
        <p>
          The great Tigers teams were built on the foundations of talent
          developed from within. With Geordan Murphy as Director of Rugby, the
          club will be putting the development of youth as a major priority and
          this will flow through our entire development programme.
        </p>
        <p>
          Our future players from our ‘Developing Player Programme’ will start
          their journey in the same black colours the club started its journey
          in back in 1880. As they rise through the ranks to the Academy, you
          will see them in our heritage colours from the 1970s and 80s before
          they finally develop into first-team players and earn their stripes…
          their new stripes.
        </p>
      </div>
    </Slide>
  )
}

GreatnessContent.propTypes = {
  config: PropTypes.object,
}

export default GreatnessContent
