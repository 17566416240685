import React from 'react'
import PropTypes from 'prop-types'
import RugbyCampListItem from './RugbyCampListItem'

const RugbyCampList = ({ onRowClick, rugbyCamps = [], ...props }) => {
  if (rugbyCamps.length === 0)
    return <p>No rugby camps found that match your search.</p>
  return (
    <div className="rugby-camp-list">
      <div className="rugby-camp-list__item rugby-camp-list__item--header is-hidden-tablet is-hidden-desktop">
        <div className="rugby-camp-list__sub-item">Details</div>
      </div>
      <div
        tabIndex="0"
        className="rugby-camp-list__item rugby-camp-list__item--header is-hidden-mobile"
      >
        <div className="rugby-camp-list__sub-item">
          <div className="rugby-camp-list__cell rugby-camp-list__cell--address-header">
            Location
          </div>
          <div className="rugby-camp-list__cell rugby-camp-list__cell--date-wrapper">
            <div className="rugby-camp-list__cell rugby-camp-list__cell--date-header">
              Date / Time
            </div>
            <div className="rugby-camp-list__cell rugby-camp-list__cell--days-header">
              Days
            </div>
          </div>
        </div>
        <div className="rugby-camp-list__sub-item">
          <div className="rugby-camp-list__cell rugby-camp-list__cell--cost-header">
            Cost
          </div>
          <div className="rugby-camp-list__cell rugby-camp-list__cell--book-header">
            <span className="is-vhidden">Book</span>
          </div>
        </div>
      </div>
      {rugbyCamps.map((rugbyCamp, i) => (
        <RugbyCampListItem
          isSelected={props.selected && props.selected._id === rugbyCamp._id}
          onRowClick={() => onRowClick(rugbyCamp)}
          key={`rugby-camp-row-${i}`}
          {...rugbyCamp}
        />
      ))}
    </div>
  )
}

RugbyCampList.propTypes = {
  rugbyCamps: PropTypes.arrayOf(PropTypes.shape(RugbyCampListItem.propTypes))
    .isRequired,
  onRowClick: PropTypes.func,
  selected: PropTypes.object,
}

export default RugbyCampList
