import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'

import Slide from '../Slide'

const Gallery = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundType="image"
      backgroundImage="rainbow-bg.jpg"
      isCentered
      isSticky={false}
      isStickyTablet
      plxEnabled={plxEnabled}
      isFullHeight={false}
      isFullHeightTablet
      id="slidegallery"
    >
      <div className="ns-gallery">
        <div className="ns-gallery__heading">
          <h2>
            <span>A</span>
            <span>Change</span>
            <span>Shirt</span>
          </h2>
        </div>
        <PlxComponent
          parallaxData={config.fg.textBottom}
          className="ns-gallery__text"
        >
          Our change shirt has been designed to reflect the colours and
          background of our vibrant and diverse city and to celebrate how
          inclusive rugby is for all. It reflects the club’s commitment to serve
          its local and rugby communities and the drive to keep Tigers a big
          part of the fabric of Leicester. OUR CLUB OUR COMMUNITY
        </PlxComponent>
        <div className="grid grid--centered">
          {Array(5)
            .fill('')
            .map((item, index) => (
              <PlxComponent
                parallaxData={
                  index % 2 === 0 ? config.fg.above : config.fg.below
                }
                className={classNames(
                  'grid__item tablet-one-fifth',
                  'ns-gallery__image'
                )}
              >
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/gallery${index + 1}.jpg`
                  )}
                />
              </PlxComponent>
            ))}
        </div>
        <img
          className="floating floating-left"
          src={versionPath(
            `/assets/img/content/new-stripes/gallery-float-left.png`
          )}
        />
        <img
          className="floating floating-right"
          src={versionPath(
            `/assets/img/content/new-stripes/gallery-float-right.png`
          )}
        />
      </div>
    </Slide>
  )
}

Gallery.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Gallery
