import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'
const baseClass = 'quote'

const Quote = ({ modifier, quote, citation }) => (
  <blockquote
    className={classNames(
      baseClass,
      'content-gutter',
      { 'content-center content-center--small': !modifier },
      { [`${baseClass}--${modifier}`]: modifier }
    )}
  >
    <Icon width={55} height={44}>
      <Svgs.Quote />
    </Icon>
    <p>{quote}</p>
    {citation && <cite>{citation}</cite>}
  </blockquote>
)

Quote.propTypes = {
  modifier: PropTypes.string,
  quote: PropTypes.string.isRequired,
  citation: PropTypes.string,
}

export default Quote
