import React from 'react'
import PropTypes from 'prop-types'
import Indicator from '../../indicator/component/Indicator'
import Icon from '../../icon/component/Icon'
import Svgs from '../../svgs/component/Svgs'
import SectionHeading from '../../section-heading/component/SectionHeading'
import Button from '../../button/component/Button'

const SummaryInner = ({
  modifiers,
  imgUrl,
  indicator,
  tag,
  title,
  text,
  href,
  meta,
  comments,
  callToActionText,
}) => (
  <div>
    {modifiers.includes('search') ? (
      <div className="summary__image-wrapper">
        <img src={imgUrl} alt="" />
      </div>
    ) : (
      !modifiers.includes('cover') && <img src={imgUrl} alt="" />
    )}
    <div className="summary__inner">
      {(indicator || tag) && !callToActionText && (
        <div className="summary__tag">
          {indicator && <Indicator text="Unread" />}
          {tag && tag}
        </div>
      )}
      {modifiers.includes('cta') ? (
        <SectionHeading heading={title} />
      ) : (
        <h2 className="h2 summary__title">{title}</h2>
      )}
      {text && <p className="summary__copy">{text}</p>}
      {modifiers.includes('cta') && (
        <div className="summary__cta-wrapper">
          <Button
            ctaText={callToActionText}
            ctaLink={href}
            modifiers={[ 'primary' ]}
          />
        </div>
      )}
    </div>
    {meta && !callToActionText && (
      <div className="summary__meta">
        {meta}
        {comments && (
          <div className="summary__comments">
            <Icon width={10} height={10}>
              <Svgs.Comment />
            </Icon>
            {comments} comments
          </div>
        )}
      </div>
    )}
  </div>
)

SummaryInner.defaultProps = {
  modifiers: [],
}

SummaryInner.propTypes = {
  callToActionText: PropTypes.string,
  tag: PropTypes.string,
  imgUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string,
  modifiers: PropTypes.array,
  indicator: PropTypes.string,
  meta: PropTypes.string,
  comments: PropTypes.string,
}

export default SummaryInner
