const debug = require('debug')('tabs')

export default function initTabs() {
  debug('init')

  const tabWidgets = Array.from(document.querySelectorAll('.js-tabs-container'))

  if (!tabWidgets || tabWidgets.length === 0) {
    debug('No tab widgets')
    return
  }
  debug(`Got ${tabWidgets.length} widgets`)
  let isMobile = window.matchMedia('(max-width: 550px)').matches

  window.addEventListener('resize', () => {
    isMobile = window.matchMedia('(max-width: 550px)').matches
  })

  tabWidgets.forEach((widget) => {
    const menu = widget.querySelector('.js-tabs-nav')
    if (!menu) return false

    const tabs = Array.from(menu.querySelectorAll('.js-tab'))
    const tabIds = tabs.map((tab) => tab.id)

    const setActiveTab = (index, setHash = true) => {
      const tab = tabs[index]
      const tabClasses = tab.classList
      const desktopBodies = Array.from(
        widget.querySelectorAll('.js-tab-body-desktop')
      )

      if (!isMobile) {
        tabs.forEach((tab) => tab.classList.remove('activeTab'))
        desktopBodies.forEach((body) => body.classList.remove('isActive'))
        desktopBodies[index].classList.add('isActive')
      }

      if (tabClasses.contains('activeTab')) {
        tabClasses.remove('activeTab')
      } else {
        tabClasses.add('activeTab')
      }

      if (setHash) {
        window.history.replaceState({}, '', '#' + tab.id)
      }
    }

    const hash = window.location.hash.replace('#', '')
    if (!isMobile || hash) {
      if (hash && tabIds.includes(hash)) {
        const activeTabIndex = tabIds.indexOf(hash)
        setActiveTab(activeTabIndex, false)
      } else {
        setActiveTab(0, false)
      }
    }

    tabs.forEach((item, index) => {
      item.querySelector('.js-tabs-button').addEventListener('click', () => {
        setActiveTab(index)
      })
    })

    window.addEventListener('hashchange', () => {
      const hash = window.location.hash.replace('#', '')
      if (hash && tabIds.includes(hash)) {
        const activeTabIndex = tabIds.indexOf(hash)
        setActiveTab(activeTabIndex)
      }
    })
  })
}
