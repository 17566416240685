import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import FormattedDate from '../../primitive/formatted-date/component/FormattedDate'
import Button from '../../primitive/button/component/Button'

const RugbyCampListItem = ({
  onRowClick,
  location,
  ageRange,
  addressLine1,
  postcode,
  startDate,
  endDate,
  cost,
  days,
  bookingLink,
  isSelected,
}) => (
  <div
    className={classNames('rugby-camp-list__item', isSelected && 'is-selected')}
    onClick={onRowClick}
  >
    <div className="rugby-camp-list__sub-item">
      <ul className="rugby-camp-list__cell rugby-camp-list__cell--address list--unstyled">
        <li>{location}</li>
        <li>{ageRange}</li>
        <li>
          {addressLine1}, {postcode}
        </li>
      </ul>
      <div className="rugby-camp-list__cell rugby-camp-list__cell--date-wrapper">
        <div className="rugby-camp-list__cell rugby-camp-list__cell--date">
          {startDate.getDate() === endDate.getDate() ? (
            <FormattedDate date={startDate} dateFormat="LL" />
          ) : (
            <span>
              <FormattedDate
                date={startDate}
                dateFormat={
                  startDate.getDate() > endDate.getDate() ? 'D MMM' : 'D'
                }
              />
              <span> - </span>
              <FormattedDate date={endDate} dateFormat="ll" />
            </span>
          )}
          <br />
          <FormattedDate date={startDate} dateFormat="LT" /> -{' '}
          <FormattedDate date={endDate} dateFormat="LT" />
        </div>
        <div className="rugby-camp-list__cell rugby-camp-list__cell--days">
          {days}
        </div>
      </div>
    </div>

    <div className="rugby-camp-list__sub-item rugby-camp-list__sub-item--book">
      <div className="rugby-camp-list__cell rugby-camp-list__cell--cost">
        {cost}
      </div>
      <div className="rugby-camp-list__cell rugby-camp-list__cell--book">
        {bookingLink ? (
          <Button
            isExternal
            ctaText="Book"
            ctaLink={bookingLink}
            modifiers={[ 'primary' ]}
          />
        ) : (
          <span>Not available</span>
        )}
      </div>
    </div>
  </div>
)

RugbyCampListItem.propTypes = {
  location: PropTypes.string.isRequired,
  ageRange: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  postcode: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  cost: PropTypes.string.isRequired,
  days: PropTypes.string.isRequired,
  onRowClick: PropTypes.func,
  bookingLink: PropTypes.string,
  isSelected: PropTypes.bool,
}

export default RugbyCampListItem
