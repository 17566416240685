import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../primitive/button/component/Button'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel'

const markerStyle = (scale) => ({
  transform: `translate(-20%, -25%) scale(${scale})`,
})

const homeMarkerStyle = () => ({
  transform: 'translate(-28px, -100%) scale(0.7)',
})

const ClusterMarker = ({ text, onClick }) => {
  return (
    <div className="rugby-camp-finder__marker" onClick={onClick}>
      <Svgs.ClusterMarker style={markerStyle(0.7)} text={text} />
    </div>
  )
}

ClusterMarker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
}

class InfoWindow extends React.Component {
  constructor(props) {
    super(props)

    this.onNextSlide = this.onNextSlide.bind(this)
    this.onPreviousSlide = this.onPreviousSlide.bind(this)

    this.state = {
      selectedCamp: this.props.selectedCamp,
      currentSlideIndex: 0,
    }
  }

  componentWillReceiveProps(props) {
    this.state.selectedCamp = props.selectedCamp
  }

  onNextSlide() {
    this.state.currentSlideIndex++
    this.state.selectedCamp = this.props.camps[this.state.currentSlideIndex]
  }

  onPreviousSlide() {
    this.state.currentSlideIndex--
    this.state.selectedCamp = this.props.camps[this.state.currentSlideIndex]
  }

  render() {
    const campIndexToShow = this.props.camps.reduce((initial, camp, index) => {
      if (
        camp.ageRange === this.state.selectedCamp.ageRange &&
        camp.location === this.state.selectedCamp.location
      ) {
        return index
      }
      return initial
    }, 0)
    return (
      <div
        className="rugby-camp-finder__marker-text js-rugby-camp-marker"
        style={{ zIndex: 1 }}
      >
        <CarouselProvider
          naturalSlideWidth={10}
          naturalSlideHeight={10}
          totalSlides={this.props.camps.length}
          currentSlide={campIndexToShow}
        >
          <Slider>
            {this.props.camps &&
              this.props.camps.map((camp, i) => {
                const {
                  addressLine1,
                  postcode,
                  location,
                  ageRange,
                  bookingLink,
                } = camp
                return (
                  <Slide index={i} key={'marker-camp-' + i}>
                    <ul className="list--unstyled">
                      <li>{location}</li>
                      <li>{ageRange}</li>
                      <li>{addressLine1},</li>
                      <li>{postcode}</li>
                    </ul>
                    <Button
                      ctaText="Book"
                      isExternal
                      ctaLink={bookingLink}
                      modifiers={[ 'primary', 'block', 'slim' ]}
                    />
                  </Slide>
                )
              })}
          </Slider>
          {this.props.camps.length > 1 && (
            <div className="carousel__button-group">
              <DotGroup dotNumbers />
              <ButtonBack
                className="button carousel__button"
                onClick={this.onPreviousSlide}
              >
                <Icon width={10} height={20}>
                  <Svgs.ArrowLeft />
                </Icon>
              </ButtonBack>
              <ButtonNext
                className="button carousel__button"
                onClick={this.onNextSlide}
              >
                <Icon width={10} height={20}>
                  <Svgs.ArrowRight />
                </Icon>
              </ButtonNext>
            </div>
          )}
        </CarouselProvider>
      </div>
    )
  }
}

InfoWindow.propTypes = {
  camps: PropTypes.array,
  selectedCamp: PropTypes.object,
}

const RugbyCampMarker = ({
  onClick,
  onCampChange,
  selected,
  selectedCamp,
  marker,
  isHome,
}) => {
  return (
    <div className="rugby-camp-finder__marker">
      {isHome && <Svgs.HomeMarker style={homeMarkerStyle()} />}
      {!isHome && (
        <div>
          <div onClick={() => onClick(marker)}>
            <Svgs.Marker style={markerStyle(selected ? 1 : 0.7)} />
          </div>
          {selected && location && (
            <InfoWindow
              onCampChange={onCampChange}
              selectedCamp={selectedCamp}
              camps={marker.camps}
            />
          )}
        </div>
      )}
    </div>
  )
}

RugbyCampMarker.propTypes = {
  onClick: PropTypes.func,
  onCampChange: PropTypes.func,
  selected: PropTypes.bool,
  selectedCamp: PropTypes.object,
  marker: PropTypes.object.isRequired,
  isHome: PropTypes.bool,
}

export { RugbyCampMarker, ClusterMarker }
