import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'

import Slide from '../Slide'
import TeamCrest from '../../../../layout/component/primitive/team-crest/component/TeamCrest'

const Manu = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundColor="green"
      backgroundType="svg"
      backgroundImage="StripesVertical"
      isFullHeight={false}
      plxEnabled={plxEnabled}
      id="slidemanu"
    >
      <React.Fragment>
        <PlxComponent
          parallaxData={config.fg.header}
          className="ns-manu__header"
        >
          <div className="ns-manu__crest">
            <TeamCrest team={{ crestId: 'leicester' }} hasDarkBG />
          </div>
        </PlxComponent>
        <div className="ns-manu__title-wrapper">
          <div>
            <PlxComponent
              parallaxData={config.fg.new}
              className="ns-manu__title ns-manu__title--primary"
              id="ns-manu__title--left"
            >
              New
            </PlxComponent>
            <PlxComponent
              parallaxData={config.fg.look}
              className="ns-manu__title ns-manu__title--primary"
              id="ns-manu__title--right"
            >
              Look
            </PlxComponent>
          </div>
          <PlxComponent
            parallaxData={config.fg.tigers}
            className="ns-manu__title ns-manu__title--secondary"
          >
            {'leicester tigers'.split('').map((letter) => (
              <div>{letter}</div>
            ))}
          </PlxComponent>
        </div>
        <img
          className="ns-manu__player"
          src={versionPath(`/assets/img/content/new-stripes/nemani.png`)}
        />
        <PlxComponent
          parallaxData={config.fg.bars}
          className="ns-manu__bars"
        >
          <div className="ns-manu__bar ns-manu__bar--white">
            <img
              src={versionPath(
                `/assets/img/content/new-stripes/toppstiles.png`
              )}
            />
            <img
              src={versionPath(`/assets/img/content/new-stripes/samurai.png`)}
            />
          </div>
          <div className="ns-manu__bar ns-manu__bar--red" />
        </PlxComponent>
      </React.Fragment>
    </Slide>
  )
}

Manu.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Manu
