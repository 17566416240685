import Swiper from 'swiper'
const debug = require('debug')('carousel')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initSwipers)
  document.addEventListener('deviceready', initSwipers)
}

const initSwipers = () => {
  document
    .querySelectorAll('.js-video-playlist-wrapper')
    .forEach((carousel) => {
      const carouselElements = carousel.querySelector(
        '.js-video-playlist:not(.is-initialised)'
      )

      Swiper(carouselElements, {
        speed: 200,
        slidesPerView: 4,
        slidesPerGroup: 4,
        nextButton: '.js-video-playlist-next',
        prevButton: '.js-video-playlist-prev',
        breakpoints: {
          799: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      })

      carousel.classList.add('is-initialised')
    })
}

export { init, initSwipers }
