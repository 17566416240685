import React from 'react'
import PropTypes from 'prop-types'

import VideoComponent from '../../../../layout/component/video/component/Video'
import Slide from '../Slide'

const Video = ({ config, isChange, plxEnabled }) => {
  const videoID = isChange ? 'NMPTf1HEogw?rel=0' : 'FgSQF0GjRHE?rel=0'
  const heading = `Leicester Tigers<br />2020-21 ${
    isChange ? 'Change' : 'Home'
  } Kit`

  return (
    <Slide
      config={config}
      backgroundType="svg"
      backgroundImage={isChange ? 'StripesHorizontal' : 'StripesVertical'}
      backgroundColor={isChange ? 'blue' : 'green'}
      isMobileCenteredColumn
      plxEnabled={plxEnabled}
    >
      <h2 className="ns-title ns-title--video" dangerouslySetInnerHTML={{ __html: heading }} />
      <VideoComponent provider="youtube" videos={[videoID]} />
    </Slide>
  )
}

Video.propTypes = {
  isChange: PropTypes.bool,
  plxEnabled: PropTypes.bool,
  config: PropTypes.object,
}

export default Video
