import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'

import Slide from '../Slide'

const Tiles = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide config={config} plxEnabled={plxEnabled} backgroundColor="grey">
      <div className="ns-tiles__background" />
      <div className="ns-tiles__container">
        <PlxComponent
          parallaxData={config.fg.welcome}
          className="ns-tiles__welcome"
        >
          Welcome
        </PlxComponent>
        <PlxComponent
          parallaxData={config.fg.images}
          className="ns-tiles__images"
        >
          <img
            src={versionPath(
              `/assets/img/content/new-stripes/topps-collection.png`
            )}
          />
        </PlxComponent>
        <PlxComponent
          parallaxData={config.fg.shirt}
          className="ns-tiles__shirt"
        >
          <img
            src={versionPath(`/assets/img/content/new-stripes/split-shirt.png`)}
          />
        </PlxComponent>
        <PlxComponent
          parallaxData={config.fg.partner}
          className="ns-tiles__partner"
        >
          New Main Partner
        </PlxComponent>
      </div>
    </Slide>
  )
}

Tiles.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Tiles
