import React from 'react'
import PropTypes from 'prop-types'
import MatchRowTab from './MatchRowTabComponent'

let message =
  'Thank you for contacting us, a member of the Group Days team will be in touch soon.'

class MatchRowTabContainer extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.prefixUrl = this.prefixUrl.bind(this)
    this.state = {
      name: '',
      emailAddress: '',
      waiting: false,
    }
  }

  onChange(e) {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value })
  }

  onSubmit(e) {
    this.setState({ waiting: true })
    e.preventDefault()

    const { name, emailAddress } = this.state

    const { matchId, checksum, toEmailAddress } = this.props

    if (toEmailAddress === 'awaytravel@tigers.co.uk') {
      message = 'Thank you for contacting us, a member of the Ticket Office will be in touch soon.'
    }

    const data = {
      name,
      emailAddress,
      matchId,
      checksum,
      toEmailAddress,
    }

    const apiUrl = '/api/enquiry'
    const apiTarget = this.prefixUrl ? this.prefixUrl(apiUrl) : apiUrl
    fetch(apiTarget, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200)
          return this.setState({ message, status: res.status })
        this.setState({
          waiting: false,
          message: 'Error. Please check your details and try again.',
        })
      })
      .catch((err) => {
        this.setState({ waiting: false, message: err.message })
      })
  }

  prefixUrl(url) {
    if (typeof SITE_URL === 'undefined') return url
    return SITE_URL + url
  }

  render() {
    return (
      <MatchRowTab
        versionPath={this.prefixUrl}
        matchId={this.matchId}
        toEmailAddress={this.toEmailAddress}
        checksum={this.checksum}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        {...this.props}
        {...this.state}
      />
    )
  }
}

MatchRowTabContainer.propTypes = {
  matchId: PropTypes.string,
  checksum: PropTypes.string,
  toEmailAddress: PropTypes.string,
  tab: PropTypes.object,
}

export default MatchRowTabContainer
