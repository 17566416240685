import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'
import TeamCrest from '../../../../layout/component/primitive/team-crest/component/TeamCrest'
import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'
import Slide from '../Slide'

const Brand = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      id="slidebrand"
      config={config}
      backgroundColor="grey"
      plxEnabled={plxEnabled}
      isFullHeight={false}
      isFullHeightDesktop
      isSticky={false}
      isStickyTablet
    >
      <div className="ns-brand">
        <h2 className="ns-slide__heading ns-slide__heading--green">
          Developing The Brand
        </h2>
        <div className="ns-brand__container">
          <PlxComponent
            parallaxData={config.fg.crest}
            className="ns-brand__crest"
          >
            <TeamCrest team={{ crestId: 'leicester' }} hasDarkBG />
          </PlxComponent>
          <PlxComponent
            parallaxData={config.fg.sketch}
            className="ns-brand__sketch-wrapper"
          >
            <div className="ns-brand__sketch">
              <picture>
                <source
                  media="(min-width:550px)"
                  srcSet={versionPath(
                    `/assets/img/content/new-stripes/sketch.png`
                  )}
                />
                <source
                  media="(min-width:320px)"
                  srcSet={versionPath(
                    `/assets/img/content/new-stripes/sketch-mobile.png`
                  )}
                />
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/sketch.png`
                  )}
                />
              </picture>
            </div>
          </PlxComponent>
          <PlxComponent
            parallaxData={config.fg.annotations}
            className="ns-brand__annotations"
          />
          <PlxComponent parallaxData={config.fg.t} className="ns-brand__t">
            <Svgs.TigersT />
          </PlxComponent>
        </div>
        <a
          className="button button--primary ns-brand__button"
          href="https://www.leicestertigers.com/TheTLogo"
        >
          Find out more
        </a>
      </div>
    </Slide>
  )
}

Brand.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Brand
