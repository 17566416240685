import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'
import commentaryTypes from '../../../../../service/match-commentary/lib/commentary-types'
import EmbedSocial from '../../../../../common/social/Embed'
import InlineImage from '../../commentary-inline-image/component/InlineImage'

const baseClass = 'commentary'

const Commentary = ({ commentary }) => {
  const {
    minute,
    text,
    heading,
    type,
    imageUrl,
    imageTitle,
    imageCaption,
    socialId,
    socialType,
  } = commentary
  const commentaryItem = commentaryTypes.getCommentaryByType(type)
  const modifierClassNames = commentaryItem.modifiers
    ? commentaryItem.modifiers.map(
        (modifierClass) => `${baseClass}--${modifierClass}`
      )
    : []
  const EventIcon = Svgs[commentaryItem.icon]
  return (
    <div className={classNames(baseClass, modifierClassNames)}>
      <div className="commentary__icon">
        {EventIcon ? (
          <Icon>
            <EventIcon />
          </Icon>
        ) : (
          <p>{minute}</p>
        )}
      </div>
      <div className="commentary__content">
        {heading && <p className="commentary__heading">{heading}</p>}
        {socialId && (
          <div
            data-commentary-type={socialType}
            data-commentary-social={socialId}
          >
            <EmbedSocial type={socialType} socialId={socialId} />
          </div>
        )}
        {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
        {imageUrl && (
          <InlineImage
            width={'full'}
            title={imageTitle}
            caption={imageCaption}
            image={imageUrl}
          />
        )}
      </div>
    </div>
  )
}

const CommentaryShape = {
  text: PropTypes.string,
  heading: PropTypes.string,
  imageUrl: PropTypes.string,
  imageTitle: PropTypes.string,
  imageCaption: PropTypes.string,
  socialId: PropTypes.string,
  socialType: PropTypes.string,
  type: PropTypes.string.isRequired,
  minute: PropTypes.number.isRequired,
}

Commentary.propTypes = {
  commentary: PropTypes.shape(CommentaryShape).isRequired,
}

export default Commentary
