import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
const baseClass = 'figure'

const Figure = ({ imgUrl, alt, caption, modifiers }) => {
  const modifierClassNames = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : null

  return (
    <figure className={classNames(baseClass, modifierClassNames)}>
      <div className="figure__image">
        <img src={imgUrl} alt={alt || caption || 'Figure image'} />
      </div>
      {caption && (
        <figcaption className="figure__caption">{caption}</figcaption>
      )}
    </figure>
  )
}

Figure.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  caption: PropTypes.string,
  modifiers: PropTypes.array,
}

export default Figure
