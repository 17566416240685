import throttle from 'lodash.throttle'
const debug = require('debug')('mobile-navigation')

export default (serviceLocator) => {
  debug('init')

  const bm = serviceLocator.get('breakpointManager')
  const navLinks = Array.from(document.querySelectorAll('.js-nav-link'))
  const primaryNav = document.querySelector('.js-navigation')
  if (!primaryNav) return false
  const rightNav = document.querySelector('.js-navigation-right')
  const moreDrop = primaryNav.querySelector('.js-menu')
  const siteTitle = document.querySelector('.site-title')
  const navMenuButton = primaryNav.querySelector('.js-open-menu')
  const tabletMaxItems = navLinks.length / 2 + 1
  const stickPosition = document.querySelector('.pre-header').clientHeight
  let availableSpace = 1000

  navLinks.map((link) => {
    link.setAttribute('data-item-width', link.clientWidth)
    if (link.classList.contains('js-has-sublinks')) {
      link.addEventListener('click', () => {
        if (link.parentNode.classList.contains('js-navigation')) {
          closeNav()
        }
        link.classList.toggle('is-open')
      })
    }
  })

  // Hack that fixes a bug where navigation items does not removed
  // on smaller screens when the page loads
  window.onload = () => {
    navLinks.forEach(() => {
      calculateNavWidth()
    })
  }

  const handleClick = (e) => {
    e.preventDefault()
    const isActive = primaryNav.classList.contains('is-open')
    isActive ? closeNav() : openNav()
  }

  const handleKeydown = (e) => {
    if (e.keyCode !== 27) return
    closeNav()
  }

  const openNav = () => {
    primaryNav.classList.add('is-open')
    document.querySelector('html').classList.add('has-open-nav')
    document.addEventListener('keydown', handleKeydown)
  }

  const closeNav = () => {
    document.querySelector('html').classList.remove('has-open-nav')
    primaryNav.classList.remove('is-open')
    document.removeEventListener('keydown', handleKeydown)
  }

  navMenuButton.addEventListener('click', handleClick)

  const calculateNavWidth = () => {
    availableSpace =
      window.innerWidth - rightNav.clientWidth - siteTitle.clientWidth
    if (primaryNav.clientWidth > availableSpace) {
      if (!primaryNav.lastChild.contains(moreDrop)) {
        moreDrop.insertBefore(primaryNav.lastChild, moreDrop.firstChild)
      }
      availableSpace =
        window.innerWidth - rightNav.clientWidth - siteTitle.clientWidth
    } else {
      if (
        moreDrop.firstChild &&
        primaryNav.clientWidth +
          Number(moreDrop.firstChild.getAttribute('data-item-width')) <
          availableSpace &&
        primaryNav.childNodes.length < tabletMaxItems
      ) {
        primaryNav.appendChild(moreDrop.firstChild)
      }
    }
  }

  const changeLogo = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop
    const isSticky = scrollTop > stickPosition
    if (isSticky) {
      document.body.classList.add('has-sticky-nav')
    } else {
      document.body.classList.remove('has-sticky-nav')
    }
  }

  bm.on('enter:mobile', () => {
    window.removeEventListener('resize', calculateNavWidth)
    while (primaryNav.childNodes.length > 1)
      moreDrop.insertBefore(primaryNav.lastChild, moreDrop.firstChild)
    primaryNav.classList.add('is-initialised')
  })

  bm.on('enter:tabletNav', () => {
    while (primaryNav.childNodes.length > tabletMaxItems)
      moreDrop.insertBefore(primaryNav.lastChild, moreDrop.firstChild)
    calculateNavWidth()
    window.addEventListener('resize', calculateNavWidth)
    primaryNav.classList.add('is-initialised')
  })

  bm.on('enter:desktopNav', () => {
    while (moreDrop.childNodes.length > 0)
      primaryNav.appendChild(moreDrop.firstChild)
    window.removeEventListener('resize', calculateNavWidth)
    primaryNav.classList.add('is-initialised')
  })

  bm.on('enter:stickyNav', () =>
    window.addEventListener('scroll', throttle(changeLogo, 160))
  )
  bm.on('exit:stickyNav', () =>
    window.removeEventListener('scroll', throttle(changeLogo, 160))
  )
}
