import matchStatuses from '../match-statuses'
import getMatchDateTime from './get-match-date-time'
const moment = require('moment-timezone')

const getMatchSummary = (match) => {
  const matchStatus = matchStatuses.all.find(
    (status) => status.value === match.status
  )
  match.dateTime = getMatchDateTime(match)
  if (typeof match.dateTime === 'string')
    match.dateTime = new Date(match.dateTime)
  return {
    period: matchStatus.text,
    kickOffTime:
      !matchStatus.hasScore &&
      moment(match.dateTime).tz('Europe/London').format('HH:mm'),
  }
}

export default getMatchSummary
