import React from 'react'
import PropTypes from 'prop-types'
import debug from 'debug'
import sortBy from 'lodash.sortby'
import Jumbotron from '../../../components/site/layout/component/jumbotron/component/Jumbotron'

const log = debug('matchday-live:jumbotron:log')

class Container extends React.Component {
  constructor(props) {
    super(props)
    this.commentary = this.props.commentary
    this.primus = this.props.primus
    const match = this.props.match
    this.state = {
      match,
      homeScore: match.__scores.home,
      awayScore: match.__scores.away,
      commentary: this.commentary,
    }
  }

  componentDidMount() {
    this.primus.on('statusUpdate', (match) => {
      log('statusUpdate', match)
      if (match._id !== this.props.match._id) return
      this.setState({ match: { ...this.state.match, ...match } })
    })
    this.primus.on('newCommentary', (newCommentary) => {
      log('newCommentary', newCommentary)
      const commentary = sortBy(
        [...this.commentary, newCommentary],
        'minute'
      ).reverse()
      this.commentary = commentary
      this.setState({ commentary })
    })

    this.primus.on('deleteCommentary', (oldCommentary) => {
      log('deleteCommentary', oldCommentary)
      const commentary = this.commentary.filter(
        (item) => item._id !== oldCommentary
      )
      this.commentary = commentary
      this.setState({ commentary })
    })

    this.primus.on('updateScore', (score) => {
      log('updateScore', score)
      this.setState({ homeScore: score.home, awayScore: score.away })
    })

    this.primus.on('commentary', (commentary) => {
      log('commentary', commentary)
      this.commentary = commentary
      this.setState({ commentary })
    })
  }

  render() {
    return (
      <Jumbotron
        match={this.state.match}
        commentary={this.state.commentary}
        homeScore={this.state.homeScore}
        awayScore={this.state.awayScore}
      />
    )
  }
}

Container.propTypes = {
  commentary: PropTypes.array,
  primus: PropTypes.object,
  match: PropTypes.object,
}

export default Container
