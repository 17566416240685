import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'
import Slide from '../Slide'

const Values = ({ config, plxEnabled }) => {
  const values = ['Club First', 'Tough', 'Passionate', 'Driven']
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundImage="StripesVertical"
      backgroundType="svg"
      backgroundColor="green"
      plxEnabled={plxEnabled}
      id="slidevalues"
    >
      <div className="ns-values">
        {values.map((value, index) => (
          <PlxComponent
            parallaxData={config.fg.values[index]}
            className="ns-values__value-wrapper"
          >
            <span className="ns-values__value">{value}</span>
          </PlxComponent>
        ))}
      </div>

      <PlxComponent
        parallaxData={config.fg.player}
        className="ns-values__player"
      />
      <PlxComponent parallaxData={config.fg.shirt} className="ns-values__shirt">
        <img
          src={versionPath(`/assets/img/content/new-stripes/half-shirt.png`)}
        />
      </PlxComponent>
    </Slide>
  )
}

Values.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Values
