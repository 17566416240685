import React from 'react'

const TikTok = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1001.773" height="1169.173">
    <path
      d="M17.067 1.138v.021c.001.395.117 6.106 6.11 6.462 0 5.313.002 0 .002 4.412-.45.027-3.944-.226-6.119-2.165l-.007 8.59c.054 3.889-2.111 7.703-6.164 8.472-1.136.215-2.159.239-3.875-.136C-2.875 23.836.41 9.169 10.34 10.739c0 4.735.003-.001.003 4.735-4.102-.604-5.474 2.809-4.384 5.252.992 2.224 5.076 2.707 6.5-.431.162-.615.243-1.315.243-2.101V1.138h4.365"
      style={{
        fillOpacity: 1,
        fillRule: 'evenodd',
        stroke: 'none',
      }}
    />
  </svg>
)

export default TikTok
