import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from '../../../../layout/component/primitive/section-heading/component/SectionHeading'
import CommentaryItem from '../../../../layout/component/commentary/component/Commentary'
import Icon from '../../../../layout/component/primitive/icon/component/Icon'
import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'

const Commentary = ({ isLive, commentary }) => {
  if (!commentary) return null
  const heading = isLive ? 'Live Commentary' : 'Commentary'
  return (
    (isLive || commentary.length > 0) && (
      <div id="match-commentary">
        <div className="auto-justify auto-justify--valign-bottom">
          <SectionHeading heading={heading} />
          {isLive && (
            <p className="commentary-auto-renew">
              <Icon width={18} height={18} className="is-spinning">
                <Svgs.AutoRenew />
              </Icon>
              <span>Commentary will automatically update.</span>
            </p>
          )}
        </div>
        {commentary.map((item, i) => (
          <CommentaryItem key={`commentary-${i}`} commentary={item} />
        ))}
        <hr />
      </div>
    )
  )
}
Commentary.propTypes = {
  commentary: PropTypes.array,
  isLive: PropTypes.bool,
}

export default Commentary
