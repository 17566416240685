import React from 'react'

const YellowCardSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.76"
    height="18.56"
    viewBox="0 0 15.76 18.56"
  >
    <path
      id="Rectangle_1066_copy"
      data-name="Rectangle 1066 copy"
      fill="#fff200"
      fillRule="evenodd"
      d="M2210.27,4673.72l11.6,3.11-4.15,15.45-11.59-3.11Z"
      transform="translate(-2206.12 -4673.72)"
    />
  </svg>
)

export default YellowCardSvg
