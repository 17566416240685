import React from 'react'

const TigersT = () => (
  <svg data-name="Layer 1" viewBox="0 0 198.01 226.77">
    <path
      d="M0 44.29h41.19v145.65a216.38 216.38 0 0 0 51 36.84V0H0z"
      fill="#034537"
    />
    <path
      d="M198 0h-92.17v226.78a216.38 216.38 0 0 0 51-36.84V44.29H198z"
      fill="#ac1f2d"
    />
  </svg>
)

export default TigersT
