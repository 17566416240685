import debug from 'debug'
import updateErrorMessage from '../schedule-filter/lib/error-message-updater'
const log = debug('table-filter:log')

export default () => {
  log('Init table filter')

  const tableFilter = document.querySelector('.js-standing--form')
  const subNavigation = document.querySelector('.js-sub-navigation')
  const subNavigationHeader = subNavigation
    ? subNavigation.querySelector('.section-heading')
    : null

  if (!tableFilter) return false

  const competitionSelect = getSelect(tableFilter, 'competition')
  const seasonSelect = getSelect(tableFilter, 'season')
  const seasonOptions = [ ...seasonSelect.querySelectorAll('option') ]
  const currentPath = window.location.pathname.split('/')
  const parentSection = currentPath[1]
  const currentCompetition = currentPath[2]
  const query = window.location.search

  let currentSeason = query.substring(query.indexOf('=') + 1)

  const seasonElement = document.querySelector('[data-season]')
  if (seasonElement) {
    currentSeason = seasonElement.dataset.season
  }
  const validSeasons = []
  const activeSeason = currentSeason || seasonSelect.value

  if (subNavigationHeader) {
    const currentHeader = subNavigationHeader.innerHTML
    const seasonTemplate = ` ${activeSeason - 1} / ${activeSeason}`
    subNavigationHeader.innerHTML = currentHeader + seasonTemplate
  }

  seasonOptions.forEach((option) => validSeasons.push(option.value))

  if (validSeasons.indexOf(currentSeason) < 0) {
    seasonSelect.value = validSeasons[0]
  } else {
    seasonSelect.value = currentSeason
  }

  competitionSelect.value = currentPath[2]

  // Apply a specific error message for Prem Rugby Cup season 2015/2016
  if (
    currentCompetition === 'premiership-rugby-cup' &&
    activeSeason === '2016'
  ) {
    const premRugbyErrorMessage =
      'In 2015/2016 there was no Premiership Rugby Cup Competition due to the Rugby World Cup.'
    updateErrorMessage(premRugbyErrorMessage)
  }

  competitionSelect.addEventListener('change', () => {
    window.location.href = `/${parentSection}/${competitionSelect.value}?season=${currentSeason}`
  })

  seasonSelect.addEventListener('change', () => {
    window.location.href = `/${parentSection}/${currentCompetition}?season=${seasonSelect.value}`
  })
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}
