import breakpoints from './breakpoints'
import createBreakpointManager from 'break'
import regg from 'regg'

import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import elementDataset from 'element-dataset'
import * as Sentry from '@sentry/browser'

const config = JSON.parse(
  document.querySelector('body').getAttribute('data-browser-config')
)

elementDataset()

const debug = require('debug')('base')
const env = process.env.NODE_ENV || 'development'
const inDevelopmentMode = env === 'development'

debug('Init')
debug('NODE_ENV', env)

if (!inDevelopmentMode) {
  Sentry.init({
    dsn: config.sentry.site.public,
    environment: env,
    release: config.release,
    ignoreErrors: [
      // Microsoft SafeLink Crawler https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Object Not Found Matching Id:',
    ],
  })
  Sentry.configureScope((scope) => {
    scope.setTag('application', 'browser')
  })
  window.Sentry = Sentry
} else {
  window.Sentry = {
    captureException(e) {
      console.error(e)
    },
  }
}

const bm = createBreakpointManager()
const serviceLocator = regg()

window.Sentry = Sentry

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    Sentry.captureException(e)
  }
}

const components = [
  require('./splash-overlay/init').default,
  require('./sticky/init').default,
  require('./carousel/init').init,
  require('./mobile-navigation/init').default,
  require('./load-more/init').default,
  require('./table-filter/init').default,
  require('./show-results/init').default,
  require('./schedule-filter/init').default,
  require('./countdown/init').default,
  require('./enquiry-widget/init').default,
  require('./nouislider/init').default,
  require('./action-list/init').default,
  require('./jump-menu/init').default,
  require('./toggler/init').default,
  require('./unique-switcher/init').default,
  require('./rugby-camp-finder/init').default,
  require('./heritage/init').default,
  require('./event-filter/init').default,
  require('./email-signup/init').default,
  require('./event-filter/init').default,
  require('./match-row-tab/init').init,
  require('./junior-tigers-signup/init').default,
  require('./next-article/init').default,
  require('./match-day-live/init').default,
  require('./form-builder/init').init,
  require('./embedded-social/init').default,
  require('./click-tracker/init').default,
  require('./polls/init').init,
  require('./videos/init').init,
  require('./calendar/init').init,
  require('./video-preview/init').init,
  require('./calendar/init').init,
  require('./mobile-match-row-actions/init').default,
  require('./pop-promotion/init').default,
  require('./new-stripes/init').default,
  require('./frequently-asked/init').default,
  require('./tabs-widget/init').default,
]

process.nextTick(() => {
  bm.add('desktop', breakpoints.desktopMq)
  bm.add('desktopNav', breakpoints.desktopNavMq)
  bm.add('tablet', breakpoints.tabletMq)
  bm.add('tabletNav', breakpoints.tabletNavMq)
  bm.add('mobile', breakpoints.mobileMq)
  bm.add('stickyNav', breakpoints.stickyNavMq)
  bm.add('actionList', breakpoints.actionListMq)
})

serviceLocator.register('breakpointManager', bm)

components.forEach((component) => component(serviceLocator, window))
