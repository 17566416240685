import React from 'react'
import PropTypes from 'prop-types'
import VideoComponent from '../../../../layout/component/video/component/Video'
import Quote from '../../../../layout/component/quote/component/Quote'
// import Plx from 'react-plx'
import Slide from '../Slide'

const TilesContent = ({ config }) => {
  return (
    <Slide isBorderless isFullHeight={false} isSticky={false} config={config}>
      <div className="ns-tiles__content">
        <h2 className="ns-text__heading">A NEW LOOK NEEDS NEW SUPPORT</h2>
        <h2 className="ns-slide__sub-heading">Topps Tiles</h2>
        <p>
          Here at Topps Tiles we are proud and very excited to be partnered with
          such a successful and iconic rugby team.
        </p>
        <p>
          We’re passionate about cementing a sporting connection to reinforce
          our projects in the local communities we are a part of, supporting our
          Youth Sport Programme which helps fund children’s sports kits for
          rugby, football and hockey across the UK as well as events for our
          company charity Macmillan Cancer Support.
        </p>
        <p>
          Topps Tiles is a business built on family values. For 57 years we’ve
          sold only the very best quality, on-trend tiles and have become the
          UK’s largest tile specialist.
        </p>
        <p>
          We are passionate about tiles and these innovatively designed products
          are created in collaboration with our partners worldwide, making
          almost all of our designs available only at Topps Tiles.
        </p>
        <p>
          In 1963 we opened our first tile shop and in 1990 we grew into the
          Topps Tiles you know and love today, now with over 300 stores across
          the UK.
        </p>
        <p>
          Visit us in store to speak to our tile specialists for expert advice
          on your tiling project. We can design what your room will look like
          with our Visualiser, recommend what essentials you’ll need to complete
          the job and take you on a design journey all from the comfort of your
          local Topps store.
        </p>
        <p>
          Visit our website{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bit.ly/38Ya9Gw"
          >
            toppstiles.co.uk
          </a>{' '}
          for the latest trends, get tiling advice via our knowledge hub and see
          examples of our tiles in different rooms including a few of our
          customer favourites! Need a little more inspiration? Make sure to
          follow us on Instagram{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://bit.ly/2OnU5o4"
          >
            @toppstiles
          </a>
          .
        </p>
        <Quote
          quote="Topps Tiles is a business built on family values. For 57 years we’ve sold only the very best quality, on-trend tiles and
have become the UK’s largest tile specialist. It therefore felt right to connect our brand with such a successful and iconic team in the world of Rugby."
          citation="Richard Carter, Retail Managing Director"
        />
      </div>
      <div className="ns-tiles__video">
        <VideoComponent provider="youtube" videos={['8y2-HxloHRI?rel=0']} />
      </div>
    </Slide>
  )
}

TilesContent.propTypes = {
  config: PropTypes.object,
}

export default TilesContent
