import schemata from '@clocklimited/schemata'
import required from '@clocklimited/validity-required'
import isEmail from 'validity-email'

const createSchema = (questions) => {
  const formResponseSchema = questions.reduce((schema, question, i) => {
    schema[`question-${i}`] = {}
    schema[`question-${i}`].validators = []
    if (question.required) {
      schema[`question-${i}`].validators.push(
        required.setFailureMessage('Response required')
      )
    }
    if (question.input === 'email') {
      schema[`question-${i}`].validators.push(isEmail)
    }
    return schema
  }, {})

  return schemata({ name: 'Polls', properties: { formResponseSchema } })
}

export default createSchema
