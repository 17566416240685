const platforms = {
  youtube: {
    name: 'YouTube Video',
    link: (id) => `https://www.youtube.com/watch?v=${id}`,
    embedUrl: (id) => `https://www.youtube.com/embed/${id}`,
    isPlaylist: false,
  },
  youtubePlaylist: {
    name: 'YouTube Playlist',
    link: (id) => `https://www.youtube.com/playlist?list=${id}`,
    embedUrl: (id) => `https://www.youtube.com/embed/${id}`,
    isPlaylist: true,
  },
  vimeo: {
    name: 'Vimeo',
    link: (id) => `https://vimeo.com/${id}`,
    embedUrl: (id) => `https://player.vimeo.com/video/${id}`,
    isPlaylist: false,
  },
}

module.exports = platforms
