import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SectionHeading from '../../component/primitive/section-heading/component/SectionHeading'

class FrequentlyAsked extends React.PureComponent {
  render() {
    const { title, questions } = this.props
    return (
      <div className="frequently-asked">
        {!!title && (
          <div className="widget__header">
            <SectionHeading heading={title} modifiers={['small']} />
          </div>
        )}
        <ul className="frequently-asked__list">
          {questions.map((item, i) => (
            <li key={i}>
              <QA question={item.question} answer={item.answer} />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

FrequentlyAsked.propTypes = {
  title: PropTypes.string,
  questions: PropTypes.array.isRequired,
}

class QA extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      open: false,
      animating: false,
    }
    this.toggleOpen = this.toggleOpen.bind(this)
    this.animate = this.animate.bind(this)
  }

  toggleOpen() {
    this.setState({ open: !this.state.open })
    this.animate()
  }

  animate() {
    this.setState({ animating: true })
    setTimeout(() => {
      this.setState({ animating: false })
    }, 250)
  }

  render() {
    const { question, answer } = this.props
    const { open } = this.state

    return (
      <Fragment>
        <button
          className="frequently-asked__question"
          onClick={this.toggleOpen}
        >
          <span className="frequently-asked__icon">{open ? '–' : '+'}</span>{' '}
          {/* <SectionHeading heading={question} /> */}
          <h2>{question}</h2>
        </button>
        <div
          className={classNames(
            'frequently-asked__answer prose',
            open && 'is-open'
          )}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </Fragment>
    )
  }
}

QA.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

export default FrequentlyAsked
