import React from 'react'
import ReactDOM from 'react-dom'
import MatchRowTabContainer from '../../../site/layout/component/match-row/component/MatchRowTabContainer'
const debug = require('debug')('group-days')

const init = () => {
  debug('Init')
  document.addEventListener('DOMContentLoaded', initMatchRowTab)
}

const initMatchRowTab = (prefixUrl) => {
  const appNodes = document.querySelectorAll('.js-match-row-tab-form')

  if (appNodes.length === 0) {
    return debug('No group days forms found. Skipping.')
  }

  appNodes.forEach((appNode) => {
    const {
      matchId,
      checksum,
      toEmailAddress,
      matchRowTabTitle,
      matchRowTabBody,
      matchRowTabImage,
    } = appNode.dataset

    const matchRowTab = {
      title: matchRowTabTitle,
      body: matchRowTabBody,
      image: matchRowTabImage,
    }

    ReactDOM.render(
      <MatchRowTabContainer
        tab={matchRowTab}
        matchId={matchId}
        checksum={checksum}
        toEmailAddress={toEmailAddress}
      />,
      appNode
    )
  })
}

export { init, initMatchRowTab }
