import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Panel = ({ heading, children, narrow }) => {
  const widthClassNames = { 'content-center content-center--small': narrow }

  return (
    <div className="panel">
      {heading && (
        <div className="panel__heading">
          <h2 className={classNames('panel__title', widthClassNames)}>
            {heading}
          </h2>
        </div>
      )}
      <div className={classNames('panel__body', widthClassNames)}>
        {children}
      </div>
    </div>
  )
}

Panel.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node.required,
  narrow: PropTypes.bool,
}

export default Panel
