import React from 'react'

const StarSvg = () => (
  <svg
    width="99"
    height="94"
    viewBox="0 0 99 94"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.73 78.385l19.667-10.34 19.668 10.34-3.756-21.9 15.91-15.51-21.988-3.195-9.834-19.925-9.833 19.925-21.99 3.195 15.912 15.51-3.756 21.9zM18.868 93.959l5.83-33.994L0 35.889l34.133-4.96L49.397 0l15.265 30.93 34.133 4.96-24.699 24.075 5.83 33.994-30.529-16.05-30.529 16.05z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default StarSvg
