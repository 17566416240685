import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'

import Icon from '../../../../layout/component/primitive/icon/component/Icon'
import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'
import Slide from '../Slide'

const Samurai = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundType="svg"
      backgroundImage="StripesVertical"
      backgroundColor="green"
      isSticky={false}
      isStickyDesktop
      isFullHeight={false}
      isFullHeightDesktop
      plxEnabled={plxEnabled}
      id="slidesamurai"
    >
      <div className="ns-samurai__shard" />
      <div className="ns-samurai">
        <div className="grid grid--spacious">
          <div className="grid__item desktop-six-tenths">
            <div className="grid">
              <div className="grid__item tablet-one-third desktop-one-quarter">
                <PlxComponent
                  parallaxData={config.fg.iconixlogo}
                  className="ns-samurai__iconix-logo"
                >
                  <img
                    src={versionPath(
                      `/assets/img/content/new-stripes/iconix.jpg`
                    )}
                  />
                </PlxComponent>
              </div>
              <div className="grid__item tablet-two-thirds desktop-three-quarters">
                <PlxComponent
                  parallaxData={config.fg.iconixtext}
                  className="ns-samurai__text ns-samurai__text--iconix"
                >
                  <p>
                    <strong>
                      The unquestionable choice of elite teams across the world.
                      Where fit, comfort, styling and strength leads to ultimate
                      performance and success
                    </strong>
                    . Our revolutionary, Iconix Test match shirt is constructed
                    with our exclusive Kohino 4DX fabric. This 4-way stretch
                    fabric has been specifically developed to produce a shirt
                    with unparalleled dynamic, body contouring fit and comfort
                    that ergonomically sculpts to your body.
                  </p>
                </PlxComponent>
              </div>
              <div className="ns-samurai__shirt">
                <picture>
                  <source
                    media="(min-width:960px)"
                    srcSet={versionPath(
                      `/assets/img/content/new-stripes/samurai-shirt.png`
                    )}
                  />
                  <source
                    media="(min-width:320px)"
                    srcSet={versionPath(
                      `/assets/img/content/new-stripes/samurai-shirt-mobile.png`
                    )}
                  />
                  <img
                    src={versionPath(
                      `/assets/img/content/new-stripes/samurai-shirt.png`
                    )}
                  />
                </picture>
                {plxEnabled && (
                  <PlxComponent
                    parallaxData={config.fg.shirt}
                    className="ns-samurai__shirt-overlay"
                  >
                    <img
                      src={versionPath(
                        `/assets/img/content/new-stripes/samurai-labels.png`
                      )}
                    />
                  </PlxComponent>
                )}
              </div>
              <div className="ns-samurai__text ns-samurai__text--shirt is-hidden-desktop ns-samurai__list">
                <p>
                  <strong>01</strong> New comfort fit collar, reducing chaffing,
                  increased comfort & harder to scrag
                </p>
                <p>
                  <strong>02</strong> Designed - in compression zones for better
                  muscular control and endurance
                </p>
                <p>
                  <strong>03</strong> Industry-leading seam, strength (e.g. 800N
                  force front-to-back) for extended shirt life
                </p>
                <p>
                  <strong>04</strong> Saddle-sleeve & aerodynamic flat seam
                  stitching for reduced drag
                </p>
                <p>
                  <strong>05</strong> Anti-pinch under arm panelling
                </p>
                <p>
                  <strong>06</strong> Kohino 4DX fabric delivers imporved
                  breathabilty, wickability, UV colour resistance &
                  anti-bacterial properties
                </p>
                <img
                  src={versionPath(
                    `/assets/img/content/new-stripes/performex.png`
                  )}
                />
              </div>
            </div>
          </div>
          <div className="grid__item desktop-four-tenths">
            <PlxComponent
              parallaxData={config.fg.samurai}
              className="ns-samurai__rhcol"
            >
              <img
                className="ns-samurai__logo"
                src={versionPath(
                  `/assets/img/content/new-stripes/samurai-white.png`
                )}
              />
              <div className="ns-samurai__text ns-samurai__text--samurai">
                <p>
                  Samurai are honoured to have been chosen by Leicester Tigers
                  for this partnership, and we feel privileged to be involved
                  with a club with Leicester’s playing heritage and history. We
                  look forward to serving both the club professionals and
                  supporters with our match day, training and leisure kit.
                </p>
                <p>
                  Our products are developed using recent industrial research,
                  exceeding international standards wherever possible, backed by
                  independent laboratory testing. We routinely performance test
                  our products against leading competitor products. We are proud
                  of our track record in creating unique team identities in
                  sport, and we look forward to seeing you in your new Leicester
                  Tigers kit soon #SamuraiFamily.
                </p>
                <p>
                  If you’re interested in using Samurai Sportswear for your
                  local club or team, get in touch with us on{' '}
                  <a href="mailto:sales@samurai-sports.com">
                    sales@samurai-sports.com
                  </a>
                  , or call our Leicester Tigers account manager Richard Brand
                  on <a href="tel:+447786023252">+44 7786-023252</a>.
                </p>
              </div>
            </PlxComponent>
          </div>
        </div>
        <div className="ns-samurai__footer">
          <div className="ns-samurai__footer-text">
            <span className="ns-samurai__statement">Designed to Last</span>
            <span className="ns-samurai__elipsis"> ...</span>
            <span className="ns-samurai__bold">Tested to Perform</span>
          </div>
          <div className="ns-samurai__line ns-samurai__line--long" />
          <div className="ns-samurai__hashtag">
            #Samurai<span>Family</span>
          </div>
          <div className="ns-samurai__line ns-samurai__line--short" />
          <div>
            <a
              href="https://bit.ly/2ZskN5l"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                className="ns-samurai__social-icon ns-samurai__social-icon--facebook"
                height={23}
                width={23}
              >
                <Svgs.Facebook />
              </Icon>
            </a>
            <a
              href="https://bit.ly/3ew9kFW"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                className="ns-samurai__social-icon ns-samurai__social-icon--twitter"
                height={22}
                width={27}
              >
                <Svgs.Twitter />
              </Icon>
            </a>
            <a
              href="https://bit.ly/32iVSmw"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                className="ns-samurai__social-icon ns-samurai__social-icon--linkedin"
                height={27}
                width={27}
              >
                <Svgs.LinkedIn />
              </Icon>
            </a>
            <a
              href="https://bit.ly/30evDuO"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                className="ns-samurai__social-icon ns-samurai__social-icon--instagram"
                height={27}
                width={27}
              >
                <Svgs.Instagram />
              </Icon>
            </a>
            <a
              href="https://bit.ly/2CEO7wE"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                className="ns-samurai__social-icon ns-samurai__social-icon--youtube"
                height={17}
                width={24}
              >
                <Svgs.Youtube />
              </Icon>
            </a>
          </div>
        </div>
      </div>
    </Slide>
  )
}

Samurai.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Samurai
