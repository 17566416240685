import React from 'react'
import PropTypes from 'prop-types'

import StandardCheckField from '../standard/StandardCheckField'
import StandardTextField from '../standard/StandardTextField'
import StandardRangeField from '../standard/StandardRangeField'

const Field = (props) => {
  const { type } = props

  const componentMap = {
    checkbox: () => <StandardCheckField {...props} />,
    radio: () => <StandardCheckField {...props} />,
    range: () => <StandardRangeField {...props} />,
  }

  return componentMap[type] ? (
    componentMap[type]()
  ) : (
    <StandardTextField {...props} />
  )
}

Field.propTypes = {
  type: PropTypes.string,
}

export default Field
