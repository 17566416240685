import debug from 'debug'
import qs from 'qs'
const log = debug('events-filter:log')

export default () => {
  log('Init events filter')

  const eventFilter = document.querySelector('.js-event--form')
  if (!eventFilter) return false

  const availabilitySelect = getSelect(eventFilter, 'availability')
  const priceSelect = getSelect(eventFilter, 'price')
  const layoutSelect = getSelect(eventFilter, 'layout')
  const sortSelect = getSelect(eventFilter, 'sort')

  let search = window.location.search.substring(1) // remove leading question mark
  const query = qs.parse(search) // create query object from query params

  // Populate all selects
  if (availabilitySelect) {
    availabilitySelect.value = query.availability || 'all'
    availabilitySelect.addEventListener('change', () => {
      const url = updateQueryParam('availability', availabilitySelect.value)
      redirectTo(url)
    })
  }
  if (priceSelect) {
    priceSelect.value = query.price || 'all'
    priceSelect.addEventListener('change', () => {
      const url = updateQueryParam('price', priceSelect.value)
      redirectTo(url)
    })
  }
  if (layoutSelect) {
    layoutSelect.value = query.layout || 'grid'
    layoutSelect.addEventListener('change', () => {
      const url = updateQueryParam('layout', layoutSelect.value)
      redirectTo(url)
    })
  }
  if (sortSelect) {
    sortSelect.value = query.sort || 'relevance'
    sortSelect.addEventListener('change', () => {
      const url = updateQueryParam('sort', sortSelect.value)
      redirectTo(url)
    })
  }
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}

// Update the query param values, if 'all' is selected, remove from query param
const updateQueryParam = (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

// build url path and redirect page
const redirectTo = (url) => {
  let path = url.path.join('/')
  const query = qs.stringify(url.query)
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
