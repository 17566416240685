import React from 'react'
import PropTypes from 'prop-types'
import Field from '../../primitive/field/component/Field'
import classnames from 'classnames'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'

const MatchRowTab = ({
  waiting,
  message,
  versionPath,
  matchId,
  checksum,
  toEmailAddress,
  onChange,
  onSubmit,
  tab,
}) => {
  const actionUrl = '/enquiry'

  return (
    <div className={classnames('match-row__group-days')}>
      <div className="grid">
        <div className="grid__item desktop-two-fifths">
          <img
            className="match-row__group-days-image"
            src={tab.image}
            alt={tab.title}
          />
        </div>
        <div className="grid__item desktop-three-fifths">
          {message ? (
            <p>{message}</p>
          ) : (
            <form
              className="form"
              action={actionUrl}
              method="post"
              onSubmit={onSubmit}
            >
              <h2 className="match-row__group-days-heading">{tab.title}</h2>
              <input
                type="hidden"
                name="toEmailAddress"
                value={toEmailAddress}
              />
              <input type="hidden" name="checksum" value={checksum} />
              {matchId && (
                <input type="hidden" name="matchId" value={matchId} />
              )}
              <p>{tab.body}</p>
              <div className="grid">
                <div className="grid__item tablet-one-half">
                  <Field
                    name="name"
                    type="text"
                    label="Name"
                    onChange={onChange}
                    required
                  />
                </div>
                <div className="grid__item tablet-one-half">
                  <Field
                    name="emailAddress"
                    type="email"
                    label="Email"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="form__actions">
                <div className="auto-justify auto-justify--align-right auto-justify--valign-middle">
                  <div className="auto-justify__item">
                    {waiting ? (
                      <LoadingSpinner isSpinning width={20} height={20} />
                    ) : (
                      <button type="submit" className="button button--primary">
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

MatchRowTab.propTypes = {
  versionPath: PropTypes.func,
  matchId: PropTypes.string,
  checksum: PropTypes.string,
  toEmailAddress: PropTypes.string,
  message: PropTypes.string,
  waiting: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  tab: PropTypes.object,
}

export default MatchRowTab
