import React from 'react'
import ReactDOM from 'react-dom'
import JuniorTigersRegistration from '../../../site/layout/component/junior-tigers-registration/component/JuniorTigersRegistration'
import createFormSubmissionContainer from '../../lib/form-submission'
const debug = require('debug')('junior-tigers-registration')
const schema = require('../../../service/junior-tigers-club/schema')

const FormSubmissionContainer = createFormSubmissionContainer({
  Component: JuniorTigersRegistration,
  schema: schema,
  apiUrl: '/api/junior-tigers-signup',
  errorMessage: 'Error. Please check your details and try again.',
  successMessage: 'Thank you for registering for the JTC.',
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document
      .querySelectorAll('.js-junior-tigers-registration')
      .forEach((appNode) => {
        ReactDOM.render(<FormSubmissionContainer />, appNode)
      })
  })
}
