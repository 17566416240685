import React from 'react'
import PropTypes from 'prop-types'
import Figure from '../../../../layout/component/primitive/figure/component/Figure'

const InlineImage = (props) => {
  const { width, position, image, title, caption, displayShards } = props
  const modifiers = []
  if (width) modifiers.push(width)
  if (position) modifiers.push(position)
  if (displayShards) modifiers.push('shards')
  if (caption) modifiers.push('caption')

  return (
    <Figure
      imgUrl={image}
      alt={title}
      caption={caption}
      modifiers={modifiers}
    />
  )
}

InlineImage.propTypes = {
  image: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  position: PropTypes.string,
  displayShards: PropTypes.bool,
}

export default InlineImage
