import React from 'react'
import ReactDOM from 'react-dom'
import PopPromotion from '../../../site/layout/component/pop-promotion/component/PopPromotion'

const debug = require('debug')('pop-promotion')

export default () => {
  debug('init')
  document.querySelectorAll('.js-pop-promotion-wrapper').forEach((wrapper) => {
    const props = JSON.parse(wrapper.getAttribute('data-props'))
    ReactDOM.render(<PopPromotion {...props} />, wrapper)
  })
}
