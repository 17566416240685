import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SummaryInner from './SummaryInner'
const baseClass = 'summary'

const Summary = ({
  modifiers,
  imgUrl,
  indicator,
  tag,
  title,
  text,
  href,
  meta,
  comments,
  callToActionText,
}) => {
  const classList = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : []
  const coverStyles = {
    backgroundImage: `url(${imgUrl})`,
  }

  return !modifiers.includes('cta') ? (
    <a
      href={href}
      className={classNames(baseClass, [ ...classList ])}
      {...(modifiers.includes('cover') && { style: coverStyles })}
    >
      <SummaryInner
        modifiers={modifiers}
        imgUrl={imgUrl}
        indicator={indicator}
        tag={tag}
        title={title}
        text={text}
        href={href}
        meta={meta}
        comments={comments}
      />
    </a>
  ) : (
    <div className={classNames(baseClass, [ ...classList ])}>
      <SummaryInner
        modifiers={modifiers}
        imgUrl={imgUrl}
        indicator={indicator}
        tag={tag}
        title={title}
        text={text}
        href={href}
        meta={meta}
        comments={comments}
        callToActionText={callToActionText}
      />
    </div>
  )
}

Summary.defaultProps = {
  modifiers: [],
}

Summary.propTypes = {
  callToActionText: PropTypes.string,
  tag: PropTypes.string,
  imgUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
  indicator: PropTypes.string,
  meta: PropTypes.string,
  comments: PropTypes.string,
}

export default Summary
