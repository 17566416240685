import React from 'react'
import PropTypes from 'prop-types'
import Slide from '../Slide'
import Plx from 'react-plx'

const Title = ({ config, isChange, plxEnabled, hideOnMobile }) => {
  const heading = `Leicester Tigers<br />2020-21 ${
    isChange ? 'Change' : 'Home'
  } Kit`
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundType="svg"
      backgroundImage={isChange ? 'StripesHorizontal' : 'StripesVertical'}
      backgroundColor={isChange ? 'blue' : 'green'}
      isCentered
      plxEnabled={plxEnabled}
      id={isChange ? 'slidetitlechange' : 'slidetitlehome'}
      className={hideOnMobile && 'ns--mobile-hidden'}
    >
      <PlxComponent
        parallaxData={isChange ? config.fg.titlechange : config.fg.titlehome}
      >
        <h2
          className="ns-title"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </PlxComponent>
      <div
        style={{ bottom: '0', position: 'absolute' }}
        id={isChange ? 'endchange' : 'endhome'}
      />
    </Slide>
  )
}

Title.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
  isChange: PropTypes.bool,
  hideOnMobile: PropTypes.bool
}

export default Title
