import React, { createRef, useState } from 'react'
import PropTypes from 'prop-types'
import SectionHeading from '../../primitive/section-heading/component/SectionHeading'
import Field from '../../primitive/field/component/Field'

const Enquiry = ({
  emailAddress,
  checksum,
  heading = 'Make an enquiry',
  children,
  fixtures = false,
  showFixtures = false,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const formRef = createRef()
  return (
    <div className="enquiry">
      <div className="content-center content-center--medium">
        <SectionHeading heading={heading} />
        {children}
        <form
          ref={formRef}
          className="form"
          action="/enquiry"
          method="post"
          onSubmit={(e) => {
            e.preventDefault()
            setHasSubmitted(true)
            formRef.current.submit()
          }}
        >
          <input type="hidden" name="form" value="enquiry" />
          <input type="hidden" name="toEmailAddress" value={emailAddress} />
          <input type="hidden" name="checksum" value={checksum} />
          <div className="grid grid--flex">
            <div className="grid__item tablet-one-half">
              <Field name="name" type="text" label="Name" required />
              <Field name="emailAddress" type="text" label="Email" required />
              <Field name="telephone" type="text" label="Telephone" />
              {showFixtures && fixtures && fixtures.length > 0 && (
                <Field
                  name="matchId"
                  type="select"
                  label="Select a fixture"
                  options={fixtures}
                />
              )}
            </div>
            <div className="grid__item tablet-one-half">
              <Field
                name="enquiry"
                type="textarea"
                label="Enquiry"
                modifiers={['flex']}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={hasSubmitted}
            className="button button--primary button--float-right"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

Enquiry.propTypes = {
  heading: PropTypes.string.isRequired,
  emailAddress: PropTypes.string.isRequired,
  checksum: PropTypes.string.isRequired,
  children: PropTypes.node,
  fixtures: PropTypes.array,
  showFixtures: PropTypes.bool,
}

export default Enquiry
