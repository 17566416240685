import React from 'react'
import PropTypes from 'prop-types'
import ArticleHeader from '../../article-header/component/ArticleHeader'
import Field from '../../primitive/field/component/Field'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'
import GoogleMapReact from 'google-map-react'
import { fitBounds } from 'google-map-react/utils'
import supercluster from 'points-cluster'
import deepDiff from 'deep-diff'
import RugbyCampList from './RugbyCampList'
import RugbyCampListItem from '../../../../layout/component/rugby-camp-finder/component/RugbyCampListItem'
import {
  RugbyCampMarker,
  ClusterMarker,
} from '../../../../layout/component/rugby-camp-finder/component/RugbyCampMarker'
const debug = require('debug')('rugby-camp-finder')

const onSubmit = (e) => e.preventDefault()

const findBounds = (markers) => {
  const ne = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.max(coord.lat, marker.latitude)
      coord.lng = Math.max(coord.lng, marker.longitude)
      return coord
    },
    { lat: -Infinity, lng: -Infinity }
  )
  const nw = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.max(coord.lat, marker.latitude)
      coord.lng = Math.min(coord.lng, marker.longitude)
      return coord
    },
    { lat: -Infinity, lng: Infinity }
  )
  const se = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.min(coord.lat, marker.latitude)
      coord.lng = Math.max(coord.lng, marker.longitude)
      return coord
    },
    { lat: Infinity, lng: -Infinity }
  )
  const sw = markers.reduce(
    (coord, marker) => {
      coord.lat = Math.min(coord.lat, marker.latitude)
      coord.lng = Math.min(coord.lng, marker.longitude)
      return ne
    },
    { lat: Infinity, lng: Infinity }
  )
  return { ne, nw, se, sw }
}

const isSelected = (selected, marker) => {
  return (
    selected &&
    marker &&
    selected.latitude === marker.latitude &&
    selected.longitude === marker.longitude
  )
}

const groupMarkersByLocation = (markers) => {
  return markers.reduce((groups, camp) => {
    const currentGroup = groups.find(
      (m) =>
        m.latitude === camp.latitude &&
        m.longitude === camp.longitude &&
        m.location !== camp.location
    )
    if (currentGroup) {
      currentGroup.camps.push(camp)
    } else {
      groups.push({
        latitude: camp.latitude,
        longitude: camp.longitude,
        camps: [ camp ],
      })
    }
    return groups
  }, [])
}

class RugbyCampFinder extends React.Component {
  constructor(...props) {
    super(...props)

    this.onChange = this.props.onChange && this.props.onChange.bind(this)
    this.onMapChange = this.onMapChange.bind(this)
    this.onMapClick = this.onMapClick.bind(this)
    this.onClusterMarkerClick = this.onClusterMarkerClick.bind(this)
    this.onCampMarkerClick = this.onCampMarkerClick.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
    this.onAgeChange = this.onAgeChange.bind(this)

    this.state = {
      center: {},
    }
  }

  onMapChange({ center, zoom, bounds }) {
    const mapBounds = { center, zoom, newBounds: bounds }
    this.setClusters(mapBounds, this.markers)
    this.setState({ mapBounds })
  }

  onMapClick(data) {
    const rugbyCampMarkers = [
      ...document.querySelectorAll('.js-rugby-camp-marker'),
    ]
    const found = rugbyCampMarkers.some((marker) => {
      return marker.contains(data.event.target)
    })
    if (found) return
    this.setState({ selected: null })
  }

  onClusterMarkerClick(cluster) {
    const mapBounds = this.getMapBounds(cluster.points)
    this.setState({ mapBounds })
  }

  onCampMarkerClick(marker) {
    const zoom = this.state.zoom
    const mapBounds = this.getMapBounds([ marker ])
    mapBounds.zoom = zoom
    this.setState(
      {
        mapBounds,
        selected: marker.camps[0],
      },
      () => {
        if (this.listWrapper) {
          const isSelectedElement = this.listWrapper.querySelector(
            '.is-selected'
          )
          const yValue = isSelectedElement ? isSelectedElement.offsetTop : 0
          this.listWrapper.scrollTop = yValue - window.innerHeight / 2
        }
      }
    )
  }

  onRowClick(rugbyCamp) {
    const mapBounds = this.getMapBounds([ rugbyCamp ])
    mapBounds.zoom = 18
    this.setState({
      mapBounds,
      selected: rugbyCamp,
    })
  }

  onAgeChange(e) {
    this.setFilteredRugbyCamps(e.currentTarget.value)
    this.setInitialMapState()
    this.onChange(e)
  }

  componentWillReceiveProps(nextProps) {
    const differences = deepDiff.diff(this.state.center, nextProps.center)
    if (nextProps.center && differences && differences.length) {
      // Only set homePoint if there is a postcode center and it is different to current state center
      const allPoints = []
      const homePoint = {
        longitude: nextProps.center.lng,
        latitude: nextProps.center.lat,
        isHome: true,
        ...nextProps.center,
        points: [
          {
            longitude: nextProps.center.lng,
            latitude: nextProps.center.lat,
            lat: nextProps.center.lat,
            lng: nextProps.center.lng,
          },
        ],
      }

      this.clusters.forEach((cluster) => allPoints.push(...cluster.points))
      allPoints.push(homePoint)

      const newBounds = this.getMapBounds(allPoints) // Calculate new map bounds with all clustered points and new homePoint

      this.setState({
        mapBounds: newBounds,
        homePoint,
        center: nextProps.center,
      })
    }
  }

  componentDidMount() {
    if (typeof document === 'undefined' || !document.querySelector) return
    this.setInitialMapState()
  }

  setInitialMapState() {
    this.markers = groupMarkersByLocation(this.filteredRugbyCamps)
    const markersArray = [ ...this.markers ]
    if (this.state.homePoint) markersArray.push(this.state.homePoint)
    const mapBounds = this.getMapBounds(markersArray)
    this.setClusters(mapBounds, this.markers)
    this.setState({ mapBounds })
  }

  getMapBounds(markers) {
    const bounds = findBounds(markers)
    const width = document.querySelector('.rugby-camp-finder__map').clientWidth
    const height = document.querySelector('.rugby-camp-finder__map')
      .clientHeight
    debug(width, height)
    const mapBounds = fitBounds(bounds, { width, height })
    if (markers.length > 0 && mapBounds.zoom === 1) {
      mapBounds.zoom = 18
      mapBounds.center = { lat: markers[0].latitude, lng: markers[0].longitude }
    }
    return mapBounds
  }

  setClusters(mapBounds, markers) {
    const points = markers.map(({ latitude, longitude, ...props }) => ({
      lat: latitude,
      lng: longitude,
      ...props,
    }))
    this.clusters = supercluster(points, {
      minZoom: 3,
      maxZoom: 15,
      radius: 60,
    })({
      center: mapBounds.center,
      zoom: mapBounds.zoom,
      bounds: mapBounds.newBounds,
    })
    this.clusters = this.clusters.map(({ wx, wy, ...props }) => ({
      lat: wy,
      lng: wx,
      ...props,
    }))
  }

  setFilteredRugbyCamps(ageRange) {
    this.filteredRugbyCamps =
      ageRange && ageRange !== 'All'
        ? this.props.rugbyCamps.filter(
            (rugbyCamp) => rugbyCamp.ageRange === ageRange
          )
        : this.props.rugbyCamps || []
  }

  render() {
    this.setFilteredRugbyCamps(this.props.ageRange)
    return (
      <div className="rugby-camp-finder">
        <div className="grid grid--gutterless grid--vh-desktop">
          <div
            className="grid__item desktop-one-half"
            ref={(c) => {
              this.listWrapper = c
            }}
          >
            <div className="content-center content-center--small content-gutter">
              <div className="rugby-camp-finder__form">
                <ArticleHeader category="Rugby Camps" title="Find a camp" />
                <form
                  className="form"
                  action=""
                  method="get"
                  onSubmit={onSubmit}
                >
                  <div className="grid">
                    <div className="grid__item tablet-one-half">
                      <Field
                        name="postcode"
                        type="text"
                        label="Find the camp closest to you"
                        placeholder="Postcode"
                        autoComplete="off"
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="grid__item tablet-one-half">
                      <Field
                        name="ageRange"
                        type="select"
                        label="Age Range"
                        options={this.props.ageRanges}
                        onChange={this.onAgeChange}
                      />
                    </div>
                  </div>
                </form>
                <div className="rugby-camp-finder__results-summary">
                  <p>
                    {this.filteredRugbyCamps.length}{' '}
                    {this.filteredRugbyCamps.length > 1 ? 'results' : 'result'}{' '}
                    found
                    {this.props.waiting && (
                      <LoadingSpinner
                        isSpinning
                        width={20}
                        height={20}
                        hasDarkBackground
                      />
                    )}
                  </p>
                  {this.props.error && <p>{this.props.error}.</p>}
                </div>
                <RugbyCampList
                  rugbyCamps={this.filteredRugbyCamps}
                  onRowClick={this.onRowClick}
                  selected={this.state.selected}
                />
              </div>
            </div>
          </div>
          <div className="grid__item desktop-one-half is-hidden-mobile is-hidden-tablet">
            <div className="rugby-camp-finder__map">
              {this.state.mapBounds && (
                <GoogleMapReact
                  onChange={this.onMapChange}
                  onClick={this.onMapClick}
                  center={this.state.mapBounds.center}
                  zoom={this.state.mapBounds.zoom}
                  bootstrapURLKeys={{
                    key: 'AIzaSyDY4IwKGTJ5YuuJIPhUCciad-Ry-YYeHDM',
                  }}
                >
                  {this.state.homePoint && (
                    <RugbyCampMarker
                      lat={this.state.homePoint.latitude}
                      lng={this.state.homePoint.longitude}
                      marker={this.state.homePoint.points[0]}
                      listWrapper={this.listWrapper}
                      isHome
                    />
                  )}
                  {this.clusters.map((cluster, i) => {
                    if (cluster.numPoints === 1) {
                      return (
                        <RugbyCampMarker
                          key={`camp-${i}`}
                          onClick={this.onCampMarkerClick}
                          selected={isSelected(
                            this.state.selected,
                            cluster.points[0]
                          )}
                          selectedCamp={this.state.selected}
                          lat={cluster.lat}
                          lng={cluster.lng}
                          marker={cluster.points[0]}
                          listWrapper={this.listWrapper}
                        />
                      )
                    }
                    return (
                      <ClusterMarker
                        key={`cluster-${i}`}
                        onClick={() => {
                          this.onClusterMarkerClick(cluster)
                        }}
                        lat={cluster.lat}
                        lng={cluster.lng}
                        text={cluster.numPoints.toString()}
                      />
                    )
                  })}
                </GoogleMapReact>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RugbyCampFinder.propTypes = {
  onChange: PropTypes.func,
  rugbyCamps: PropTypes.arrayOf(PropTypes.shape(RugbyCampListItem.propTypes))
    .isRequired,
  ageRanges: PropTypes.array,
  ageRange: PropTypes.string,
  center: PropTypes.object,
  error: PropTypes.string,
  waiting: PropTypes.bool,
}

export default RugbyCampFinder
