import React from 'react'

const FlagEnglandSvg = () => (
  <svg
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 720 480"
    width="720"
    height="480"
  >
    <path fill="#FFF" d="m0,0h720v480H0" />
    <path stroke="#C8102E" strokeWidth="96" d="m0,240h720M360,0v480" />
  </svg>
)

export default FlagEnglandSvg
