import React from 'react'

const ArrowDownCircleSvg = () => (
  <svg width="35" height="35" viewBox="0 0 35 35">
    <g transform="translate(-55 -34)">
      <g
        transform="translate(55 34)"
        fill="rgba(0,0,0,0.56)"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <circle cx="17.5" cy="17.5" r="17.5" stroke="none" />
        <circle cx="17.5" cy="17.5" r="17" fill="none" />
      </g>
      <path
        id="arrow"
        d="M63.44,51.5l7.889,8.347L79.548,51.5"
        transform="translate(1 -4)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
)

export default ArrowDownCircleSvg
