import debug from 'debug'
import qs from 'qs'
import updateErrorMessage from '../schedule-filter/lib/error-message-updater'

const log = debug('fixtures-results-filter:log')

export default () => {
  log('Init fixtures and results filter')

  const scheduleFilter = document.querySelector('.js-schedule--form') // Schedule filter form
  if (!scheduleFilter) return false
  const subNavigation = document.querySelector('.js-sub-navigation')
  const subNavigationHeader = subNavigation
    ? subNavigation.querySelector('.section-heading')
    : null
  const fixturesInfo = document.querySelector('.js-fixtures-info')

  // Form selects
  const squadSelect = getSelect(scheduleFilter, 'squad')
  const competitionSelect = getSelect(scheduleFilter, 'competition')
  const overrideSeason = getSelect(scheduleFilter, 'overrideSeason')
  const seasonSelect = getSelect(scheduleFilter, 'season')
  const coverageSelect = getSelect(scheduleFilter, 'coverage')
  const venueSelect = getSelect(scheduleFilter, 'venue')

  // Seasons
  const seasonOptions = [...seasonSelect.querySelectorAll('option')]

  // Get current path
  const currentPath = window.location.pathname.split('/')
  const rootLocation = currentPath[1]
  let search = window.location.search.substring(1) // remove leading question mark
  const query = qs.parse(search) // create query object from query params

  // On first load use default season value
  const filterSeasonValue = query && query.season
  const overrideSeasonValue = overrideSeason && overrideSeason.value
  if (!filterSeasonValue && overrideSeasonValue) {
    const url = updateQueryParam('season', overrideSeasonValue)
    redirectTo(url)
  }

  // Get current season (if present)
  const currentSeason = query.season
  const activeSeason = currentSeason || seasonSelect.value
  const validSeasons = [] // Used to verify if season can be selected

  // Used to populate the season select with either selected season or top option
  // without, the season select does not show text
  seasonOptions.forEach((option) => validSeasons.push(option.value))

  const latestSeason = Math.max.apply(null, validSeasons)

  if (latestSeason !== Number(activeSeason) && fixturesInfo) {
    fixturesInfo.style.display = 'none'
  }

  if (validSeasons.indexOf(currentSeason) < 0) {
    seasonSelect.value = validSeasons[0]
  } else {
    seasonSelect.value = currentSeason
  }

  if (subNavigationHeader) {
    const currentHeader = subNavigationHeader.innerHTML
    const seasonTemplate = ` ${activeSeason - 1} / ${activeSeason}`
    subNavigationHeader.innerHTML = currentHeader + seasonTemplate
  }

  // Populate all other selects
  competitionSelect.value = currentPath[3] || 'all'
  const currentCompetition = competitionSelect.value

  // Apply a specific error message for Anglo Welsh Cup season 2015/2016
  if (
    currentCompetition === 'premiership-rugby-cup' &&
    activeSeason === '2016'
  ) {
    const premRugbyCupErrorMessage =
      'In 2015/2016 there was no Premiership Rugby Cup Competition due to the Rugby World Cup.'
    updateErrorMessage(premRugbyCupErrorMessage)
  }

  if (coverageSelect) coverageSelect.value = query.coverage || 'all'
  venueSelect.value = query.venue || 'all'
  squadSelect.value = currentPath[2]

  // Add event listeners to listen for when selects change
  squadSelect.addEventListener('change', () => {
    const url = updatePath(rootLocation, squadSelect.value)
    window.location.href = url
  })

  competitionSelect.addEventListener('change', () => {
    const selectedOption =
      competitionSelect.options[competitionSelect.selectedIndex]
    const squad = selectedOption.getAttribute('data-squad')
    const url = updatePath(rootLocation, squad, competitionSelect.value)
    window.location.href = url
  })

  seasonSelect.addEventListener('change', () => {
    const url = updateQueryParam('season', seasonSelect.value)
    redirectTo(url)
  })

  venueSelect.addEventListener('change', () => {
    const url = updateQueryParam('venue', venueSelect.value)
    redirectTo(url)
  })

  if (coverageSelect) {
    coverageSelect.addEventListener('change', () => {
      const url = updateQueryParam('coverage', coverageSelect.value)
      redirectTo(url)
    })
  }
}

const getSelect = (form, id) => {
  return form.querySelector(`#control--${id}`)
}

// Create a valid destination url from squad and competition select values
const updatePath = (rootLocation, squad, competition) => {
  const pathArray = [squad]
  if (competition && competition !== 'all') pathArray.push(competition)
  const path = pathArray.join('/')

  let destinationUrl = `/${rootLocation}/${path}`
  return destinationUrl
}

// Update the query param values, if 'all' is selected, remove from query param
const updateQueryParam = (property, value) => {
  const url = getCurrentUrl()
  if (value === 'all') {
    delete url.query[property]
  } else {
    url.query[property] = value
  }
  return url
}

// build url path and redirect page
const redirectTo = (url) => {
  let path = url.path.join('/')
  const query = qs.stringify(url.query)
  if (query) path = path.concat('?', query)
  window.location.href = path
}

// get the current pages url, used to build a location object
const getCurrentUrl = () => {
  const currentUrl = {}
  const search = window.location.search
  currentUrl.path = window.location.pathname.split('/')
  currentUrl.query = qs.parse(search.substring(1))

  return currentUrl
}
