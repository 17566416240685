import React from 'react'
import PropTypes from 'prop-types'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import getMatchSummary from '../../../../../service/match/lib/match-summary-helper'

const MatchScore = ({ match, homeScore, awayScore }) => {
  if (!match) return null
  const homeTeam = match.__homeTeam
  const awayTeam = match.__awayTeam
  if (!match || !homeTeam || !awayTeam) return null

  const { time, period, kickOffTime } = getMatchSummary(match)
  const data = [
    {
      team: homeTeam,
      score: homeScore,
    },
    {
      team: awayTeam,
      score: awayScore,
    },
  ]
  return (
    <div className="match-score">
      <div className="match-score__teams">
        {data.map((item, i) => (
          <div key={`match-score-team-${i}`} className="match-score__team">
            <div className="auto-justify">
              <span className="match-score__crest">
                {item.team.crestId && <TeamCrest hasDarkBG team={item.team} />}
              </span>
              <span className="match-score__score">{item.score}</span>
            </div>
            <span className="match-score__team-name">
              {item.team.displayName}
            </span>
          </div>
        ))}
      </div>
      <div className="match-score__status">
        <div className="auto-justify">
          {(time || kickOffTime) && <span>{time || kickOffTime}</span>}
          {!kickOffTime && <span>{period}</span>}
        </div>
      </div>
    </div>
  )
}
MatchScore.propTypes = {
  match: PropTypes.object.isRequired,
  homeScore: PropTypes.number.isRequired,
  awayScore: PropTypes.number.isRequired,
}

export default MatchScore
