import React from 'react'
import ReactDOM from 'react-dom'
import PollContainer from './PollContainer'
const debug = require('debug')('poll')

const getEncoded = (node) => {
  const encodedFormNode = node || debug('No encoded data')
  if (!encodedFormNode) return false
  const formJson = encodedFormNode.innerHTML
  return JSON.parse(formJson)
}

const render = (containerNode) => {
  const poll = getEncoded(containerNode.querySelector('.js-encoded-poll'))
  const results = getEncoded(containerNode.querySelector('.js-encoded-results'))
  const pollId = poll._id
  debug('Poll found', poll._id)
  const selected = window.localStorage.getItem('poll:' + pollId)
  const onSuccess = ({ id, selected }) => {
    window.localStorage.setItem('poll:' + id, selected)
  }
  return ReactDOM.render(
    <PollContainer
      poll={poll}
      results={results}
      selected={selected}
      onSuccess={onSuccess}
    />,
    containerNode
  )
}

const initReact = () => {
  debug('Looking for polls')
  document.querySelectorAll('.js-poll').forEach((containerNode) => {
    containerNode.classList.remove('js-poll')
    render(containerNode)
  })
}

const init = () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', initReact, false)
  // app:NewDOMContent is a custom event emitted by our app
  document.addEventListener('app:NewDOMContent', initReact, false)
}

export { init, initReact }
