import React from 'react'
import ReactDOM from 'react-dom'
import MatchStats from '../../../../site/layout/component/match-stats/component/MatchStats'
import PlayerMatchStats from '../../../../site/layout/component/player-match-stats/component/PlayerMatchStats'

export default (appNodes, primus, match, type) => {
  class StatsContainer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        stats: match.__matchStats,
      }
    }

    componentDidMount() {
      primus.on('liveMatchStats', (stats) => {
        this.setState({ stats })
      })
    }

    render() {
      match.__matchStats = this.state.stats
      if (type === 'match') {
        return (
          <MatchStats
            homeTeam={match.__homeTeam}
            awayTeam={match.__awayTeam}
            homeMatchStats={match.__matchStats.homeTeamStats}
            awayMatchStats={match.__matchStats.awayTeamStats}
          />
        )
      } else {
        return (
          <PlayerMatchStats
            homeTeam={match.__homeTeam}
            awayTeam={match.__awayTeam}
            homeTeamLineUp={match.__matchStats.homeLineUp}
            awayTeamLineUp={match.__matchStats.awayLineUp}
          />
        )
      }
    }
  }

  appNodes.forEach((appNode) => {
    ReactDOM.render(<StatsContainer />, appNode)
  })
}
