import React from 'react'
import PropTypes from 'prop-types'

const HomeMarkerSvg = ({ style }) => (
  <svg
    width="56"
    height="63"
    viewBox="0 0 56 63"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M52 48.85c5.12 1.15 4.46 4.15-1.41 6.73S20.13 63 20.13 63s6.22-8.84 12.1-11.45C38.11 48.94 47 47.74 52 48.85z"
        fill="#141414"
        opacity=".5"
      />
      <path
        d="M21 0c11.598 0 21 9.402 21 21 0 11.6-21 42-21 42S0 32.6 0 21C0 9.402 9.402 0 21 0z"
        fill="#004135"
      />
      <g fill="#FFF" fillOpacity=".98">
        <path d="M30.668 19.87l-2.796-2.622V12.89a.613.613 0 0 0-.59-.605h-1.536a.613.613 0 0 0-.59.605v1.775l-3.15-2.985a.773.773 0 0 0-1.064 0l-8.663 8.189c-.315.322-.354.806-.04 1.13a.749.749 0 0 0 1.103.04l8.112-7.665 8.111 7.705c.512.403.945.12 1.103-.04a.842.842 0 0 0 0-1.17z" />
        <path d="M21.454 14.465l-7.64 7.22v9.156h5.671v-5.163h3.938v5.163h5.67v-9.156c-.04 0-7.64-7.22-7.64-7.22z" />
      </g>
    </g>
  </svg>
)

HomeMarkerSvg.propTypes = {
  style: PropTypes.object,
}

export default HomeMarkerSvg
