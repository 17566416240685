const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const isUrl = require('validity-url')
const isNumber = require('validity-number')
const validateIfSet = require('validity-validate-if-set')
const dateBeforeEndDate = require('validity-date-before-property')(
  'endDate',
  'End Date'
)
const threeDays = 1000 * 60 * 60 * 24 * 3

module.exports = (serviceLocator) =>
  schemata({
    name: 'Rugby camp schema',
    properties: {
      _id: {
        type: String,
        tag: ['public'],
      },
      location: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      type: {
        type: Number,
        validators: [required],
        tag: ['public'],
      },
      ageRange: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      startDate: {
        type: Date,
        validators: [required, dateBeforeEndDate],
        tag: ['public'],
        defaultValue: () => new Date(),
      },
      endDate: {
        type: Date,
        validators: [required],
        tag: ['public'],
        defaultValue: () => new Date(Date.now() + threeDays),
      },
      addressLine1: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      addressLine2: {
        type: String,
        tag: ['public'],
      },
      addressLine3: {
        type: String,
        tag: ['public'],
      },
      town: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      county: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      postcode: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      days: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      cost: {
        type: String,
        validators: [required],
        tag: ['public'],
      },
      latitude: {
        type: Number,
        validators: [validateIfSet(isNumber)],
        tag: ['public'],
      },
      longitude: {
        type: Number,
        validators: [validateIfSet(isNumber)],
        tag: ['public'],
      },
      bookingLink: {
        type: String,
        validators: [validateIfSet(isUrl)],
        tag: ['public'],
      },
      createdDate: {
        type: Date,
        defaultValue: () => new Date(),
        tag: ['public'],
      },
      loc: {
        type: schemata({
          name: 'Rugby Camp location schema',
          properties: {
            type: {
              type: String,
              validators: [required],
              defaultValue: 'Point',
            },
            coordinates: { type: Array, validators: [required] },
          },
        }),
      },
    },
  })
