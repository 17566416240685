import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
const baseClass = 'section-heading'

const SectionHeading = ({ heading, modifiers }) => {
  const modifierClassNames = modifiers
    ? modifiers.map((modifierClass) => `${baseClass}--${modifierClass}`)
    : []
  const headingClassNames = classNames(baseClass, [ ...modifierClassNames ])

  if (modifiers) {
    return <h2 className={headingClassNames}>{heading}</h2>
  }

  const headingArray = heading.split(' ')
  const firstItem = headingArray.shift()
  return (
    <h2 className={headingClassNames}>
      <span className="section-heading__emphasis">{firstItem}</span>
      {headingArray.length > 0 && ' ' + headingArray.join(' ')}
    </h2>
  )
}

SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
}

export default SectionHeading
