import React from 'react'
import PropTypes from 'prop-types'
const baseClass = 'pop-promotion'

class PopPromotion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // initially `true` so it doesn't flash for users with it dismissed
      isDismissed: true,
    }
    this.dismissPopup = this.dismissPopup.bind(this)
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const hasDismissed = window.localStorage.getItem(
        `pop-promotion--${this.props.id}`
      )
      this.setState({
        isDismissed: !!hasDismissed,
      })
    } else {
      this.setState({
        isDismissed: false,
      })
    }
  }

  dismissPopup(e) {
    const { trackingCode } = this.props
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(`pop-promotion--${this.props.id}`, true)
      if (window.ga && e.target) {
        const tracked =
          e.target.dataset.isPrimary === 'true'
            ? 'linkFollowed'
            : 'modalDismissed'
        window.ga('gtm1.send', 'event', `${trackingCode}`, `${tracked}`)
      }
    }
    this.setState({
      isDismissed: !this.state.isDismissed,
    })
  }

  render() {
    const { isDismissed } = this.state
    const { imgUrl, promotionUrl, ctaLink, ctaText, dismissText } = this.props
    return (
      <div>
        {!isDismissed && (
          <div>
            <div
              style={imgUrl ? { backgroundImage: `url(${imgUrl})` } : null}
              className={baseClass}
            >
              <a className="pop-promotion__dismiss" onClick={this.dismissPopup}>
                Close
              </a>
              <div className="content-center content-center--medium pop-promotion__inner">
                <div className="grid">
                  <div className="grid__item mobile-one-whole">
                    {promotionUrl && (
                      <a
                        target="_blank"
                        rel="noopener"
                        href={ctaLink}
                        onClick={this.dismissPopup}
                        data-is-primary
                        className="pop-promotion__image-wrapper"
                      >
                        <img
                          className="pop-promotion__image"
                          src={promotionUrl}
                          alt="Promotion Image"
                        />
                      </a>
                    )}
                  </div>
                  <div className="grid__item mobile-one-whole tablet-one-half desktop-one-half">
                    {ctaText && ctaLink && (
                      <a
                        className="button button--primary"
                        target="_blank"
                        rel="noopener"
                        href={ctaLink}
                        onClick={this.dismissPopup}
                        data-is-primary
                      >
                        {ctaText}
                      </a>
                    )}
                  </div>
                  <div className="grid__item mobile-one-whole tablet-one-half desktop-one-half">
                    {dismissText && (
                      <a
                        className="button button--primary"
                        onClick={this.dismissPopup}
                      >
                        {dismissText}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <a className="pop-promotion__dimmer" onClick={this.dismissPopup} />
          </div>
        )}
      </div>
    )
  }
}

PopPromotion.propTypes = {
  imgUrl: PropTypes.string,
  promotionUrl: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  dismissText: PropTypes.string,
  trackingCode: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default PopPromotion
