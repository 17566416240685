import React from 'react'
import PropTypes from 'prop-types'
import Plx from 'react-plx'
import versionPath from '../../../../../../site/lib/version-path'

import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'
import Slide from '../Slide'

const Greatness = ({ config, plxEnabled }) => {
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide backgroundColor="white" config={config} plxEnabled={plxEnabled}>
      <h2 className="ns-slide__heading--green ns-greatness__heading">
        Developing Greatness
      </h2>
      <div className="ns-greatness">
        <PlxComponent
          parallaxData={config.fg.good}
          className="ns-greatness__image"
        >
          <span>Good</span>
          <Svgs.Greatness1 />
          <img src={versionPath(`/assets/img/content/new-stripes/good.png`)} />
        </PlxComponent>
        <PlxComponent
          parallaxData={config.fg.great}
          className="ns-greatness__image"
        >
          <span>Great</span>
          <Svgs.Greatness2 />
          <img src={versionPath(`/assets/img/content/new-stripes/great.png`)} />
        </PlxComponent>
        <PlxComponent
          parallaxData={config.fg.greater}
          className="ns-greatness__image"
        >
          <span>Greater</span>
          <Svgs.Greatness3 />
          <img
            src={versionPath(`/assets/img/content/new-stripes/greater.png`)}
          />
          <div className="ns-slide__heading--green ns-greatness__text">OUR INSPIRATION</div>
        </PlxComponent>
      </div>
    </Slide>
  )
}

Greatness.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Greatness
