import React from 'react'
import PropTypes from 'prop-types'
import formatter from 'cf-formatter'
import moment from 'moment'

// TODO: Can't use config from fs in browser. Solve this duplication.
const config = {
  locale: {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'D MMM YYYY',
      LL: 'D MMMM YYYY',
      LLL: 'D MMMM YYYY LT',
      LLLL: 'dddd D MMMM YYYY LT',
    },
    calendar: {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[Last] dddd [at] LT',
      nextWeek: 'dddd [at] LT',
      sameElse: 'LLLL',
    },
  },
  timezone: 'Europe/London',
  formats: {
    timezone: 'LLLL z',
    iso: 'YYYY-MM-DDTHH:mm:ss[Z]',
    isoFileSystem: 'YYYYMMDDTHHmmss[Z]',
    S: 'D MMM',
    M: 'dddd D MMMM',
    MLL: 'dddd D MMMM YYYY',
  },
}
const format = formatter(config)
const defaultFormat = 'LLL'

const FormattedDate = ({ date, dateFormat }) => {
  if (!dateFormat) dateFormat = defaultFormat

  const momentDate = moment(date)
  const formattedDate = format(momentDate, dateFormat, config.timezone)
  return <span>{formattedDate}</span>
}

FormattedDate.propTypes = {
  date: PropTypes.object,
  dateFormat: PropTypes.string,
}

export default FormattedDate
