import React from 'react'
import PropTypes from 'prop-types'
import MatchStatsRow from './MatchStatsRow'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'

const MatchStats = ({ homeTeam, awayTeam, homeMatchStats, awayMatchStats }) => {
  if (!homeMatchStats || !awayMatchStats) return null

  return (
    <div className="match-stats">
      <h3 className="match-stats__heading">Match Stats</h3>
      <div className="match-stats__crest match-stats__crest--home">
        {homeTeam && <TeamCrest team={homeTeam} />}
      </div>
      <div className="match-stats__crest match-stats__crest--away">
        {awayTeam && <TeamCrest team={awayTeam} />}
      </div>
      <div className="match-stats__inner">
        <MatchStatsRow
          label="Possession"
          homeValue={homeMatchStats.possession}
          awayValue={awayMatchStats.possession}
          isPercentage
        />
        <MatchStatsRow
          label="Territory"
          homeValue={homeMatchStats.territory}
          awayValue={awayMatchStats.territory}
          isPercentage
        />
        <MatchStatsRow
          label="Penalties Conceded"
          homeValue={homeMatchStats.penaltiesConceded}
          awayValue={awayMatchStats.penaltiesConceded}
        />
        <MatchStatsRow
          label="Yellow Cards"
          homeValue={homeMatchStats.yellowCards}
          awayValue={awayMatchStats.yellowCards}
        />
        <MatchStatsRow
          label="Red Cards"
          homeValue={homeMatchStats.redCards}
          awayValue={awayMatchStats.redCards}
        />
        <MatchStatsRow
          label="Tackles Won"
          homePreValueText={
            homeMatchStats.tacklesWon +
            '/' +
            (homeMatchStats.tacklesWon + homeMatchStats.tacklesMissed)
          }
          homeValue={homeMatchStats.tacklesSuccess}
          awayPreValueText={
            awayMatchStats.tacklesWon +
            '/' +
            (awayMatchStats.tacklesWon + awayMatchStats.tacklesMissed)
          }
          awayValue={awayMatchStats.tacklesSuccess}
          isDouble
          isPercentage
        />
        <MatchStatsRow
          label="Scrums Won"
          homePreValueText={
            homeMatchStats.scrumsWon +
            '/' +
            (homeMatchStats.scrumsWon + homeMatchStats.scrumsLost)
          }
          homeValue={homeMatchStats.scrumsSuccess}
          awayValue={awayMatchStats.scrumsSuccess}
          awayPreValueText={
            awayMatchStats.scrumsWon +
            '/' +
            (awayMatchStats.scrumsWon + awayMatchStats.scrumsLost)
          }
          isDouble
          isPercentage
        />
        <MatchStatsRow
          label="Lineouts Won"
          homePreValueText={
            homeMatchStats.lineOutsWon +
            '/' +
            (homeMatchStats.lineOutsWon + homeMatchStats.lineOutsLost)
          }
          homeValue={homeMatchStats.lineOutSuccess}
          awayPreValueText={
            awayMatchStats.lineOutsWon +
            '/' +
            (awayMatchStats.lineOutsWon + awayMatchStats.lineOutsLost)
          }
          awayValue={awayMatchStats.lineOutSuccess}
          isDouble
          isPercentage
        />
      </div>
    </div>
  )
}

MatchStats.propTypes = {
  homeMatchStats: PropTypes.object.isRequired,
  awayMatchStats: PropTypes.object.isRequired,
  homeTeam: PropTypes.object.isRequired,
  awayTeam: PropTypes.object.isRequired,
}

export default MatchStats
