import debug from 'debug'
import throttle from 'lodash.throttle'

const log = debug('splash-overlay:log')

export default (serviceLocator) => {
  log('Init Splash Overlay')

  const overlay = document.querySelector('.js-splash-overlay')
  if (!overlay) return false

  let hasSeen = false

  try {
    hasSeen = window.localStorage.getItem('overlaySeen')
    window.localStorage.setItem('overlaySeen', true)
  } catch (e) {
    hasSeen = true
  }

  if (hasSeen) {
    return false
  }

  const video = overlay.querySelector('.js-splash-overlay-video')
  const sources = [ ...overlay.querySelectorAll('.js-splash-overlay-source') ]

  sources.forEach((source) => {
    source.setAttribute('src', source.getAttribute('data-src'))
  })

  video.load()
  video.play()

  overlay.classList.add('is-visible')

  const hasOverlay = () => window.scrollY < window.innerHeight
  const toggleOverlay = () => {
    document.body.classList.toggle('has-splash-overlay', hasOverlay())
  }

  toggleOverlay()

  const throttler = throttle(toggleOverlay, 160)

  window.addEventListener('scroll', throttler)
}
