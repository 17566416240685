import React from 'react'
import PropTypes from 'prop-types'

const MarkerSvg = ({ style }) => (
  <svg width="56" height="63" viewBox="0 0 56 63" style={style}>
    <path
      d="M52,48.85C57.12,50,56.46,53,50.59,55.58S20.13,63,20.13,63s6.22-8.84,12.1-11.45S47,47.74,52,48.85Z"
      fill="#141414"
      opacity="0.5"
    />
    <path
      d="M21,0A21,21,0,0,1,42,21c0,11.6-21,42-21,42S0,32.6,0,21A21,21,0,0,1,21,0Z"
      fill="#c10a28"
    />
    <path
      d="M33.11,20.66c-0.41-1.27-2-2.06-3.64-3.56-0.57-.53-1.15-3.42-4.64-5.44A11.87,11.87,0,0,0,19.7,10a4.79,4.79,0,0,0-4.18-1.8l-0.9.07h0l0,0a2.85,2.85,0,0,1,1.55,1.32,3.32,3.32,0,0,0,2.38,1.61,3.42,3.42,0,0,1-2.27-.73h0c-2.17,1-7.12,4.65-4.35,11.22H12a5.88,5.88,0,0,1,.12-2.56,3.73,3.73,0,0,0,.57,1.36c-1.52-7,4-8.9,4-8.9-4.34,4.21-1.35,11.29-1.35,11.29h0a5.6,5.6,0,0,1,.05-1.6h0c0.94,3.51,4.81,5.94,4.81,5.94l0,0C16,20.62,19.13,14.7,23.7,15.79a0.49,0.49,0,0,0,0,.06c-5.18.77-6.77,12.3,2,13.87-6.34.86-11.14-8-15.76-7.18,4.14,0.52,8.59,7.58,14.36,8,3,0.23,3-1.21,2.4-1.44l-0.2-.06a0.92,0.92,0,0,0,0-.14,7.61,7.61,0,0,0-.09-1.86h0s-0.48.54-1,1.08c-0.06-.71-0.05-0.75-0.07-1.64a2.52,2.52,0,0,1-1.09.82A5,5,0,0,0,24.32,26a2.55,2.55,0,0,1-1,.43,6.55,6.55,0,0,0,2.22,2.47c-4.91-2.81-4.26-9.21-1.56-9.54,2.49-.3,2.19,3.12,5.71,4.54A6.91,6.91,0,0,1,26.71,22a3.33,3.33,0,0,1-.51,1.25,10.22,10.22,0,0,0,1.08-.37,4.68,4.68,0,0,1-.49,1.7,3.72,3.72,0,0,0,1.8-.86c-0.3.77-.41,1-0.41,1s-0.33.69-.33,0.7a3.38,3.38,0,0,0,2.38-1.3,1.35,1.35,0,0,0,1.67-.93c0.15-1.58-1.45-2.6-.55-2.85,0.31-.09.34,0.64,1.13,0.91A0.47,0.47,0,0,0,33.11,20.66ZM21.87,26.58l0,0c-1.47-.92-3.27-7.61,1.5-9l0,0.05C20.06,20.5,21.24,25.54,21.87,26.58Zm-4.06-2.49,0,0C13.3,17.83,16.7,9.73,23.86,12.52v0.07c-7.41-.53-7.32,8.79-6,11.5h0ZM24.89,17c0-.08,0-0.06,0-0.13,0.43,0,.75-0.47,1.59-0.47l0.17,0.77s0.3-.07.24-0.29l-0.4-.48h0l0,0h0a6,6,0,0,0-4.7-2.29,0.27,0.27,0,0,0,0-.06c4.62-1.61,6.09,2.7,6.83,3.28l0,0a1.51,1.51,0,0,1-1.32-.2,1,1,0,0,1-.38.55,1,1,0,0,0,0,.66v0c-0.81,0-1.14-1.21-2-1.38h0Zm1.36,2.52c1.58,0.49,2.75,3.61,4.89,3.8-2.22.68-4.58-3.57-4.89-3.8h0Zm5.29,3.55c-2.13.21-3.81-3.55-4.22-3.82v0c1.84,0.66,2,3,4.21,3.84h0Z"
      fill="#fff"
    />
  </svg>
)

MarkerSvg.propTypes = {
  style: PropTypes.object,
}

export default MarkerSvg
