import React from 'react'
import ReactDOM from 'react-dom'
import NewStripesComponent from '../../../site/widgets/new-stripes/component/NewStripesComponent'

const debug = require('debug')('new stripes')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-parallax').forEach((appNode) => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      if (window.matchMedia('(max-width: 959px)').matches) {
        ReactDOM.render(
          <NewStripesComponent plxEnabled={false} {...props} />,
          appNode
        )
      } else {
        ReactDOM.render(<NewStripesComponent plxEnabled {...props} />, appNode)
      }
    })
  })
}
