import React from 'react'
import PropTypes from 'prop-types'
import Poll from '../../../site/layout/component/poll/component/Poll'
import createSchema from '../form-builder/schema'
import schemata from '@clocklimited/schemata'
import createFormSubmissionContainer from '../../lib/form-submission'
const apiPrefix = typeof API_URL === 'undefined' ? '' : API_URL

class PollContainer extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = {
      results: this.props.results,
      selected: this.props.selected,
    }
    this.onViewResults = this.onViewResults.bind(this)
    this.onEnter = this.onEnter.bind(this)
    this.PollForm = this.createPollForm()
  }

  createPollForm() {
    const poll = this.props.poll
    const pollId = poll._id
    const baseSchema = createSchema(poll.questions)
    return createFormSubmissionContainer({
      Component: (props) => (
        <Poll
          poll={poll}
          {...this.props}
          {...props}
          {...this.state}
          onViewResults={this.onViewResults}
          onEnter={this.onEnter}
        />
      ),
      schema: schemata({
        name: 'Polls',
        properties: { ...baseSchema.getProperties() },
      }),
      apiUrl: '/api/poll/' + pollId,
      errorMessage:
        'There was a problem submitting your choice. Please try again later.',
      successMessage: poll.thanksMessage,
      onSuccess: async (data, setState) => {
        this.props.onSuccess &&
          this.props.onSuccess({ id: pollId, selected: data['question-0'] })
        const results = await (
          await fetch(`${apiPrefix}/api/poll/${pollId}/results`)
        ).json()
        this.setState({
          results: results,
          view: 'results',
          selected: data['question-0'],
        })
      },
    })
  }

  onViewResults() {
    this.setState({
      view: 'results',
    })
  }

  onEnter() {
    this.setState({
      view: 'poll',
    })
  }

  render() {
    return <this.PollForm />
  }
}

PollContainer.propTypes = {
  results: PropTypes.object.isRequired,
  selected: PropTypes.string,
  poll: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default PollContainer
