import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'

const CalendarEvent = (props) => {
  const event = props.event

  const commonAttributes = {
    className: classNames('rbc-event-inner', `is-${event.type}`),
  }

  const Element = event.href ? 'a' : 'div'

  return (
    <Element href={event.href} {...commonAttributes}>
      {event.crestId && (
        <span className="rbc-event-crest">
          <TeamCrest team={event} />
        </span>
      )}
      {event.title}
    </Element>
  )
}

CalendarEvent.propTypes = {
  event: PropTypes.object,
}

export default CalendarEvent
