const schemata = require('@clocklimited/schemata')
const required = require('@clocklimited/validity-required')
const email = require('validity-email')

module.exports = schemata({
  name: 'Junior tigers club schema',
  properties: {
    guardianTitle: { type: String, validators: [required] },
    guardianFirstName: { type: String, validators: [required] },
    guardianLastName: { type: String, validators: [required] },
    childFirstName: { type: String, validators: [required] },
    childLastName: { type: String, validators: [required] },
    childDob: { type: String, validators: [required] },
    addressLine1: { type: String, validators: [required] },
    addressLine2: { type: String },
    addressLine3: { type: String },
    town: { type: String, validators: [required] },
    county: { type: String, validators: [required] },
    postcode: { type: String, validators: [required] },
    hearAbout: { type: String, validators: [required] },
    hearAboutOther: { type: String },
    email: { type: String, validators: [email] },
    newsletterPromotionsConsentGiven: { type: Boolean },
  },
})
