import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import Icon from '../../primitive/icon/component/Icon'
import Svgs from '../../primitive/svgs/component/Svgs'

const PlayerMatchStatsTable = ({ unique, lineUp, team, additionalCells }) => (
  <div
    className={classNames(
      'player-match-stats__table-wrapper',
      'js-unique-content',
      { 'is-hidden': team.crestId !== 'leicester' }
    )}
    data-unique={unique}
  >
    <table className="table">
      <thead>
        <tr>
          <th>
            <div className="player-match-stats__crest is-hidden-mobile">
              {team && <TeamCrest team={team} />}
            </div>
          </th>
          <th className="player-match-stats__fixed-col">T</th>
          <th className="player-match-stats__fixed-col">C</th>
          <th className="player-match-stats__fixed-col">D</th>
          <th className="player-match-stats__fixed-col">P</th>
        </tr>
      </thead>
      <tbody>
        {lineUp.map((player, i) => (
          <tr key={i}>
            <td className="player-match-stats__name-wrapper">
              <span className="player-match-stats__number">
                {player.redCards > 0 ? (
                  <Icon height={15} width={15}>
                    <Svgs.RedCard />
                  </Icon>
                ) : (
                  player.yellowCards > 0 && (
                    <Icon height={15} width={15}>
                      <Svgs.YellowCard />
                    </Icon>
                  )
                )}
                {player.position}
              </span>
              <span className="player-match-stats__name">
                {player.fullName}
              </span>
            </td>
            <td>{player.tries > 0 && `${player.tries}T`}</td>
            <td>{player.conversions > 0 && `${player.conversions}C`}</td>
            <td>{player.dropGoals > 0 && `${player.dropGoals}D`}</td>
            <td>{player.penaltyKicks > 0 && `${player.penaltyKicks}P`}</td>
          </tr>
        ))}
        {additionalCells > 0 &&
          [ ...Array(additionalCells) ].map((cell, i) => (
            <tr key={i}>
              <td colSpan="5">
                <span className="player-match-stats__number">&nbsp;</span>
                <span className="player-match-stats__name">&nbsp;</span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
)

const LineUpShape = PropTypes.shape({
  redCards: PropTypes.number,
  yellowCards: PropTypes.number,
  tries: PropTypes.number,
  conversions: PropTypes.number,
  dropGoals: PropTypes.number,
  penaltyKicks: PropTypes.number,
  position: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
})

PlayerMatchStatsTable.propTypes = {
  unique: PropTypes.string.isRequired,
  lineUp: PropTypes.arrayOf(LineUpShape).isRequired,
  additionalCells: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
}

export default PlayerMatchStatsTable
