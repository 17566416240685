import React from 'react'
import PropTypes from 'prop-types'
import Slide from '../Slide'

const TilesContent = ({ config }) => {
  return (
    <Slide
      isCentered
      isBorderless
      isFullHeight={false}
      isSticky={false}
      config={config}
      isShortMobile
    >
      <div className="ns-values__wrapper">
        <div className="ns-values__text">
          CLUB FIRST | TOUGH | PASSIONATE | DRIVEN
          <br />
          The club has reinforced our values by including them in the shirt.
          <br />
          These are the standards a Tiger will be judged upon.
        </div>
        <a
          className="button button--primary ns-values__button"
          href="https://www.leicestertigers.com/TheClubValues"
        >
          Find out more
        </a>
      </div>
    </Slide>
  )
}

TilesContent.propTypes = {
  config: PropTypes.object,
}

export default TilesContent
