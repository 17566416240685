import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../../../layout/component/primitive/icon/component/Icon'
import Svgs from '../../../layout/component/primitive/svgs/component/Svgs'

import Manu from './Manu'
import Video from './Video'
import Text from './Text'
import Tiles from './Tiles'
import TilesContent from './Tiles/Content'
import Samurai from './Samurai'
import Greatness from './Greatness'
import GreatnessContent from './Greatness/Content'
import Shirt from './Shirt'
import Gallery from './Gallery'
import Values from './Values'
import ValuesContent from './Values/Content'
import Brand from './Brand'
import PreOrder from './PreOrder'
import Title from './Title'

const tooltips = [
  'Homepage',
  'Leicester Tigers 2020-21 Home Kit',
  '2020-21 Home Kit Video',
  'Leicester Tigers 2020-21 Change Kit',
  '2020-21 Change Kit Video',
  'Introduction',
  'New Main Partner – Topps Tiles',
  'A New Look Needs New Support',
  'Samurai Iconix 220S',
  'Developing Greatness',
  'Developing Greatness Explained',
  'New Stripes',
  'Our Club Our Community',
  'A Change Shirt',
  'Club Values',
  'Club Values Explained',
  'Developing the Brand',
  'Pre-order',
]

class NewStripesComponent extends PureComponent {
  constructor(props) {
    super(props)

    this.scrollTo = this.scrollTo.bind(this)
  }

  scrollTo(index) {
    const links = document && [...document.querySelectorAll('.ns-slide-anchor')]
    links[index].scrollIntoView({ behavior: 'smooth' })
  }

  componentDidMount() {
    if (typeof document === 'undefined' || !document.querySelector) return

    // First slide animations
    const manuHeader = document.querySelector('.ns-manu__header')
    const manuTitleLeft = document.querySelector('#ns-manu__title--left')
    const manuTitleRight = document.querySelector('#ns-manu__title--right')
    const manuTitleSecondary = document.querySelector(
      '.ns-manu__title--secondary'
    )
    const manuBars = document.querySelector('.ns-manu__bars')
    const manu = document.querySelector('.ns-manu__player')

    manu.classList.add('ns-manu__animation-fade-in--no-delay')
    manuHeader.classList.add('ns-manu__animation-fade-in')
    manuTitleLeft.classList.add('ns-manu__animation-slide-in--left')
    manuTitleRight.classList.add('ns-manu__animation-slide-in--right')
    manuTitleSecondary.classList.add('ns-manu__animation-fade-in')
    manuBars.classList.add('ns-manu__animation-fade-in')

    // Vertical scroll nav

    const slides = [...document.querySelectorAll('.ns-slide-anchor')]
    const buttons = [...document.querySelectorAll('.ns__progress-list')]
    const scrollIndicator = document.getElementById('scroll-indicator')

    let lastSlide = slides[0]

    const isStuck = (e) => {
      if (!e.length) return

      // Find the last match (by reversing and finding the first)
      const newLastSlide = e.reverse().find((el) => el.intersectionRatio > 0.5)
      if (newLastSlide) lastSlide = newLastSlide.target

      // Hack to get back to button one when scrolling up
      if (window.scrollY < window.innerHeight / 2) lastSlide = slides[0]

      const lastSlideIndex = slides.indexOf(lastSlide)

      buttons.map((btn, i) => btn.classList.remove('ns__progress--active'))
      buttons[lastSlideIndex].classList.add('ns__progress--active')

      // Hides scroll down indicator after first slide
      if (lastSlideIndex >= 1) {
        scrollIndicator.classList.add('ns__scroll-indicator--hidden')
      } else {
        scrollIndicator.classList.remove('ns__scroll-indicator--hidden')
      }
    }

    const observer = new IntersectionObserver(isStuck, {
      threshold: [0.25, 0.75],
    })

    slides.map((slide) => {
      observer.observe(slide)
    })
  }

  render() {
    const { config, plxEnabled } = this.props
    const {
      slide,
      manu,
      title,
      tiles,
      samurai,
      greatness,
      shirt,
      gallery,
      values,
      brand,
      preorder,
    } = config

    return (
      <div
        className={classNames(
          'ns',
          plxEnabled ? 'plx-enabled' : 'plx-disabled'
        )}
      >
        <ul className="ns__progress">
          {tooltips.map((tooltip, i) => (
            <li
              className={classNames('ns__progress-list')}
              onClick={() => this.scrollTo(i)}
              key={tooltip + i}
            >
              <a className="ns__progress-dot" key={`progress-dot-${i}`} />
              <span className="ns__tooltip">
                <div>{tooltip}</div>
              </span>
            </li>
          ))}
        </ul>
        <div className="ns__wrapper">
          <span
            className="ns-slide-anchor ns__anchor--offset"
            style={{ top: '80px' }}
          />
          <Manu plxEnabled={false} config={{ bg: slide, fg: manu }} />
          <span className="ns-slide-anchor" />
          <Title
            hideOnMobile
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: title }}
          />
          <span className="ns-slide-anchor" />
          <Video plxEnabled={plxEnabled} config={{ bg: slide }} />
          <span className="ns-slide-anchor" />
          <Title
            plxEnabled={plxEnabled}
            hideOnMobile
            config={{ bg: slide, fg: title }}
            isChange
          />
          <span className="ns-slide-anchor" />
          <Video plxEnabled={plxEnabled} config={{ bg: slide }} isChange />
          <span className="ns-slide-anchor" />
          <Text plxEnabled={plxEnabled} config={{ bg: slide }} />
          <span className="ns-slide-anchor" />
          <Tiles plxEnabled={plxEnabled} config={{ bg: slide, fg: tiles }} />
          <span className="ns-slide-anchor" />
          <TilesContent />
        </div>
        <div className="ns__wrapper">
          <span className="ns-slide-anchor" />
          <Samurai
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: samurai }}
          />
          <span className="ns-slide-anchor" />
          <Greatness
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: greatness }}
          />
          <span
            className="ns-slide-anchor ns__anchor--offset"
            style={{ top: '-300px' }}
          />
          <GreatnessContent />
        </div>
        <div className="ns__wrapper">
          <span className="ns-slide-anchor" />
          <Shirt plxEnabled={plxEnabled} config={{ bg: slide, fg: shirt }} />
          <span className="ns-slide-anchor" />
          <Shirt
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: shirt }}
            isChange
          />
          <span className="ns-slide-anchor" />
          <Gallery
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: gallery }}
          />
          <span className="ns-slide-anchor" />
          <Values plxEnabled={plxEnabled} config={{ bg: slide, fg: values }} />
          <span className="ns-slide-anchor" />
          <ValuesContent />
        </div>
        <div className="ns__wrapper">
          <span className="ns-slide-anchor" />
          <Brand plxEnabled={plxEnabled} config={{ bg: slide, fg: brand }} />
          <span className="ns-slide-anchor" />
          <PreOrder
            plxEnabled={plxEnabled}
            config={{ bg: slide, fg: preorder }}
          />
        </div>
        <div id="scroll-indicator" className="ns__scroll-indicator-wrapper">
          <Icon className="ns__scroll-indicator" height={35} width={35}>
            <Svgs.ArrowDownCircle />
          </Icon>
          Scroll
        </div>
      </div>
    )
  }
}

NewStripesComponent.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default NewStripesComponent
