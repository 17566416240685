import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import once from 'once'
import throttle from 'lodash.throttle'
import VisibilitySensor from 'react-visibility-sensor'
import activate from './lib/article-indicator-activator'
import { initSwipers } from '../carousel/init'
import initCountdowns from '../countdown/init'

const debug = require('debug')('next-article')

const loadArticle = async (id) => {
  return (await fetch(`/api/articles/${id}/rendered`)).json()
}

class NextArticle extends React.Component {
  constructor(...args) {
    super(...args)
    this.loadNext = this.loadNext.bind(this)
    this.articles = this.props.articles
    this.state = {
      items: [],
    }
    this.pageTracks = {}
    this.loadNext()
  }

  async loadNext() {
    debug('loadNext')
    if (this.articles.length === 0) return
    const id = this.articles.pop()
    const article = await loadArticle(id)
    this.setState(
      {
        items: [
          ...this.state.items,
          {
            id,
            url: article.url,
            html: article.html,
            onSeen: once(this.loadNext),
          },
        ],
      },
      () => {
        var event

        if (typeof Event === 'function') {
          event = new Event('app:NewDOMContent')
        } else {
          event = document.createEvent('Event')
          event.initEvent('app:NewDOMContent', true, true)
        }

        document.dispatchEvent(event)
      }
    )
  }

  onVisiblityChange({ id, url, onSeen }) {
    return (visible, ...rest) => {
      if (visible) {
        debug(url, visible, rest)
        history.replaceState({}, 'Page', url)
        this.trackPage(url)
        activate(id)
        onSeen()
      }
    }
  }

  trackPage(url) {
    if (!window.ga || this.pageTracks[url]) return false
    window.ga('gtm1.send', 'pageview', url)
    debug('GA tracking', url)
    this.pageTracks[url] = true
    initSwipers()
    initCountdowns()
  }

  render() {
    return (
      <div>
        {this.state.items
          .map((item, i) => [
            <VisibilitySensor
              scrollCheck
              partialVisibility
              key={`endless__check-${i}`}
              onChange={this.onVisiblityChange(item)}
            >
              <div
                key={`endless__content-${i}`}
                dangerouslySetInnerHTML={{ __html: item.html }}
              />
            </VisibilitySensor>,
            <hr key={`endless__break-${i}`} />,
          ])
          .reduce((components, some) => components.concat(some), [])}
      </div>
    )
  }
}

NextArticle.propTypes = {
  articles: PropTypes.array,
}

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.querySelector('.js-next-article')
    if (!appNode) return
    const articles = JSON.parse(appNode.dataset.ids)
    if (!appNode) {
      return debug('No next article found.')
    }
    setTimeout(() => window.scrollTo(0, 0), 1000)
    const throttledReplaceUrl = throttle(
      (url) => history.replaceState({}, '', url),
      2000
    )
    const onScroll = throttle(() => {
      if (window.scrollY < 200) {
        throttledReplaceUrl(orginalUrl)
        window.__entity && activate(window.__entity._id)
      }
    }, 500)
    const orginalUrl = document.location.href
    document.addEventListener('scroll', onScroll)
    ReactDOM.render(<NextArticle articles={articles} />, appNode)
  })
}
