import React from 'react'
import PropTypes from 'prop-types'
import Timeline from '../../timeline/component/Timeline'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import classNames from 'classnames'

import matchStatuses from '../../../../../service/match/match-statuses'
import getMatchSummary from '../../../../../service/match/lib/match-summary-helper'

const Jumbotron = ({ match, commentary, homeScore, awayScore }) => {
  const statusesWithScores = matchStatuses.hasScore.map(({ value }) => value)
  const homeTeam = match.__homeTeam
  const awayTeam = match.__awayTeam

  // Only show jumbotron if team data is available and the match has a status with a score to show
  if (
    !match.isLive &&
    (!homeTeam || !awayTeam || statusesWithScores.indexOf(match.status) < 0)
  )
    return null

  const { period, kickOffTime } = getMatchSummary(match)
  return (
    <div>
      <a name="match-jumbotron" className="offset-anchor" />
      <div className="jumbotron">
        <div
          className={classNames('jumbotron__score', {
            'jumbotron--short': match.status === 'Result',
          })}
        >
          <div className="jumbotron__team">
            <div className="jumbotron__crest">
              {homeTeam.crestId && <TeamCrest hasDarkBG team={homeTeam} />}
            </div>
            <div className="jumbotron__team-name">{homeTeam.displayName}</div>
            <div className="jumbotron__team-score">{homeScore}</div>
          </div>
          <div className="jumbotron__score-block is-hidden-mobile">
            <div className="jumbotron__clock-wrapper">
              <div className="jumbotron__clock">
                <p className="jumbotron__status">{kickOffTime || period}</p>
              </div>
              {match.status !== 'Result' && (
                <div className="jumbotron__status-block">LIVE</div>
              )}
            </div>
          </div>
          <div className="jumbotron__team">
            <div className="jumbotron__crest">
              {awayTeam.crestId && <TeamCrest hasDarkBG team={awayTeam} />}
            </div>
            <div className="jumbotron__team-name">{awayTeam.displayName}</div>
            <div className="jumbotron__team-score">{awayScore}</div>
          </div>
        </div>
        {commentary && commentary.length > 0 && (
          <div className="jumbotron__timeline">
            <Timeline match={match} commentary={commentary} />
          </div>
        )}
        {match.status !== 'Result' && (
          <div className="jumbotron__mobile-footer">
            <div className="auto-justify">
              <span>{kickOffTime || period}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Jumbotron.propTypes = {
  match: PropTypes.object.isRequired,
  commentary: PropTypes.array,
  homeScore: PropTypes.number,
  awayScore: PropTypes.number,
}

export default Jumbotron
