import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TeamCrest from '../../primitive/team-crest/component/TeamCrest'
import PlayerMatchStatsTable from './PlayerMatchStatsTable'

const PlayerMatchStats = ({
  homeTeam,
  awayTeam,
  homeTeamLineUp,
  awayTeamLineUp,
}) => {
  if (
    !homeTeamLineUp ||
    homeTeamLineUp.length === 0 ||
    !awayTeamLineUp ||
    awayTeamLineUp.length === 0
  )
    return null
  const maxPlayers = Math.max(homeTeamLineUp.length, awayTeamLineUp.length)
  return (
    <div className="player-match-stats">
      <h3 className="player-match-stats__heading">Player Stats</h3>
      {[homeTeam, awayTeam].map((team, i) => (
        <div
          key={i}
          className={classNames(
            'player-match-stats__tab',
            'is-hidden-tablet',
            'is-hidden-desktop',
            'js-unique-switch',
            { 'is-active': team.crestId === 'leicester' }
          )}
          data-unique={i === 0 ? 'homeStats' : 'awayStats'}
        >
          <div className="player-match-stats__crest">
            {team && <TeamCrest team={team} />}
          </div>
        </div>
      ))}
      <PlayerMatchStatsTable
        lineUp={homeTeamLineUp}
        team={homeTeam}
        additionalCells={maxPlayers - homeTeamLineUp.length}
        unique="homeStats"
      />
      <PlayerMatchStatsTable
        lineUp={awayTeamLineUp}
        team={awayTeam}
        additionalCells={maxPlayers - awayTeamLineUp.length}
        unique="awayStats"
      />
    </div>
  )
}

PlayerMatchStats.propTypes = {
  homeTeam: PropTypes.object.isRequired,
  awayTeam: PropTypes.object.isRequired,
  homeTeamLineUp: PropTypes.array.isRequired,
  awayTeamLineUp: PropTypes.array.isRequired,
}

export default PlayerMatchStats
