import React from 'react'
import PropTypes from 'prop-types'
import getCrestPath from '../../../../lib/getCrestPath'
import versionPath from '../../../../../../../site/lib/version-path'
import slug from 'slugg'

const TeamCrest = ({ team, hasDarkBG }) => {
  const displayName = team.displayName || team.name
  const abbreviation =
    team.abbreviation ||
    (team.name ? team.name.substring(0, 3).toUpperCase() : 'UNKNOWN')
  const crestName = team.crestId || slug(team.name)
  const fileName = getCrestPath(crestName, hasDarkBG)
  const isBlank = fileName.indexOf('blank') > 0

  return (
    <span className="team-crest">
      <img
        className="team-crest__image"
        src={versionPath(fileName)}
        alt={displayName}
      />
      {isBlank && <p className="team-crest__text">{abbreviation}</p>}
    </span>
  )
}

TeamCrest.propTypes = {
  team: PropTypes.object.isRequired,
  hasDarkBG: PropTypes.bool,
}

export default TeamCrest
