import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import platforms from '../../../../../common/video/platforms'
import VideoThumbnailCarousel from './VideoThumbnailCarousel'
import VideoThumbnailGrid from './VideoThumbnailGrid'

class Video extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedVideo: props.isPlaylist
        ? props.videos[0].videoId
        : props.videos[0],
    }

    this.updateVideoSrc = this.updateVideoSrc.bind(this)
  }

  updateVideoSrc(video, scrollToVideo) {
    this.setState({
      selectedVideo: `${video.videoId}?autoplay=1`,
    })

    const scrollOffset = 150
    const heroVideoScrollTop =
      this.videoPlaylistWrapper.offsetTop - scrollOffset
    document.body.scrollTop = heroVideoScrollTop
    document.documentElement.scrollTop = heroVideoScrollTop
  }

  render() {
    const {
      isPlaylist,
      width,
      position,
      backgroundShape,
      provider,
      layout,
    } = this.props
    const platform = platforms[provider]

    const InlineVideo = () => (
      <figure
        className={classNames('inline-video', {
          'inline-video--with-playlist': isPlaylist,
          [`inline-video--${width}`]: width,
          [`inline-video--${position}`]: position,
          'inline-video--background': backgroundShape,
        })}
      >
        <div className="inline-video__shard" />
        <iframe
          src={platform.embedUrl(this.state.selectedVideo)}
          title="Video"
          width="500"
          height="281"
          frameBorder="0"
          webkitallowfullscreen="webkitallowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          allowFullScreen="allowfullscreen"
        />
      </figure>
    )

    if (!isPlaylist) return <InlineVideo />

    const thumbnailProps = {
      ...this.props,
      onThumbnailClick: this.updateVideoSrc,
    }

    return (
      <div
        className="video-playlist"
        ref={(videoPlaylistWrapper) => {
          this.videoPlaylistWrapper = videoPlaylistWrapper
        }}
      >
        <InlineVideo />

        <div className="video-playlist__thumbnails">
          {isPlaylist && layout === 'Carousel' && (
            <VideoThumbnailCarousel {...thumbnailProps} />
          )}
          {isPlaylist && layout === 'Grid' && (
            <VideoThumbnailGrid {...thumbnailProps} />
          )}
        </div>
      </div>
    )
  }
}

Video.defaultProps = {
  layout: 'Carousel',
}

Video.propTypes = {
  header: PropTypes.string,
  width: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  backgroundShape: PropTypes.bool,
  videos: PropTypes.array,
  isPlaylist: PropTypes.bool,
  src: PropTypes.string,
  provider: PropTypes.string,
  layout: PropTypes.string,
}

export default Video
