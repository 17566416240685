import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'
import Panel from '../../primitive/panel/component/Panel'
import PollQuestion from './PollQuestion'
import PollResults from './PollResults'
import moment from 'moment-timezone'

const Poll = ({
  onSubmit,
  onEnter,
  onViewResults,
  onChange,
  data = {},
  error = {},
  waiting,
  successMessage,
  errorMessage,
  poll,
  results,
  selected,
  view = 'poll',
}) => {
  const { closeDate, questions, ctaText, description } = poll
  const closed = closeDate && closeDate < Date.now()

  const entered = !!selected
  if (entered) view = 'results'
  const onViewResultsClick = (e) => {
    e.preventDefault()
    onViewResults()
  }
  const onEnterClick = (e) => {
    e.preventDefault()
    onEnter()
  }
  return (
    <Panel heading={poll.title} narrow>
      <div className="poll-builder">
        {description && (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        )}
        {errorMessage && (
          <div className="notification notification--error">
            <p>{errorMessage}</p>
          </div>
        )}
        {successMessage && (
          <div
            className="notification notification--success"
            dangerouslySetInnerHTML={{ __html: successMessage }}
          />
        )}
        <form
          className="form"
          action="/api/poll"
          method="post"
          onSubmit={onSubmit}
        >
          {view === 'poll' ? (
            questions &&
            questions.map((question, i) => (
              <PollQuestion
                key={`form-builder-${i}`}
                question={question}
                error={error}
                data={data}
                id={i}
                onChange={onChange}
                results={results}
                selected={selected}
              />
            ))
          ) : (
            <div>
              <div className="field__question">{questions[0].question}</div>
              <PollResults results={results} selected={selected} />
            </div>
          )}
          <div className="auto-justify auto-justify--valign-middle">
            {closed ? (
              <span>Poll is now closed</span>
            ) : poll.closeDate ? (
              <span>
                Poll ends {moment().tz('Europe/London').to(poll.closeDate)}
              </span>
            ) : (
              <span />
            )}
            {view === 'results' && !closed && !entered && (
              <button className="button button--primary" onClick={onEnterClick}>
                Enter Now
              </button>
            )}
            {view === 'poll' && (
              <button
                className="button button--transparent-inverted"
                onClick={onViewResultsClick}
              >
                View results
              </button>
            )}
            {view === 'poll' &&
              !closed &&
              !entered &&
              (waiting ? (
                <LoadingSpinner />
              ) : (
                !selected && (
                  <button type="submit" className="button button--primary">
                    {ctaText}
                  </button>
                )
              ))}
          </div>
        </form>
      </div>
    </Panel>
  )
}

Poll.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  waiting: PropTypes.bool,
  onEnter: PropTypes.func,
  onViewResults: PropTypes.func,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  view: PropTypes.string,
  error: PropTypes.object,
  poll: PropTypes.object,
  results: PropTypes.object,
  selected: PropTypes.string,
}

export default Poll
