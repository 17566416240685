import React from 'react'
import PropTypes from 'prop-types'
import SectionHeading from '../../primitive/section-heading/component/SectionHeading'
import Field from '../../primitive/field/component/Field'
import LoadingSpinner from '../../primitive/loading-spinner/component/LoadingSpinner'

const titles = [ 'Mr', 'Mrs', 'Miss', 'Ms', 'NA' ]

const referred = [
  'Matchday',
  'Tigers Rugby Camps',
  'Tigers Website',
  'Matchday Coaching Clinics',
  'Tigers Group Days',
  'Friends &amp; Family',
  'School or Rugby Club',
  'Other',
]
const optionMap = (item) => ({ text: item, value: item })

const JuniorTigersRegister = ({
  onSubmit,
  onChange,
  apiUrl,
  data = {},
  error = {},
  waiting,
  successMessage,
  errorMessage,
}) => {
  return (
    <div className="junior-tigers-register">
      <div className="content-center content-center--small">
        <SectionHeading heading="Join the Junior Tigers" />
        {successMessage ? (
          <p>{successMessage}</p>
        ) : (
          <form
            className="form"
            action={apiUrl}
            method="post"
            onSubmit={onSubmit}
          >
            {error && Object.keys(error).length > 0 && (
              <div className="notification notification--error">
                <h3 className="notification__title">
                  There was an problem with your form.
                </h3>
                {errorMessage && <p>{errorMessage}</p>}
                <p>Please check below and try again.</p>
              </div>
            )}
            <div className="grid">
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="childFirstName"
                  value={data.childFirstName}
                  error={error.childFirstName}
                  type="text"
                  label="Child's First Name"
                  required
                />
              </div>
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="childLastName"
                  value={data.childLastName}
                  error={error.childLastName}
                  type="text"
                  label="Child's Last Name"
                  required
                />
              </div>
            </div>
            <Field
              onChange={onChange}
              name="childDob"
              value={data.childDob}
              error={error.childDob}
              type="text"
              label="Child's Date of Birth (dd/mm/yyyy)"
              required
            />
            <hr />
            <Field
              onChange={onChange}
              name="guardianTitle"
              value={data.guardianTitle}
              error={error.guardianTitle}
              type="select"
              label="Parent/Guardian Title"
              options={[
                { text: '--- Please Select ---', value: '' },
                ...titles.map(optionMap),
              ]}
              required
            />
            <div className="grid">
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="guardianFirstName"
                  value={data.guardianFirstName}
                  error={error.guardianFirstName}
                  type="text"
                  label="Parent/Guardian First Name"
                  required
                />
              </div>
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="guardianLastName"
                  value={data.guardianLastName}
                  error={error.guardianLastName}
                  type="text"
                  label="Parent/Guardian Last Name"
                  required
                />
              </div>
            </div>
            <Field
              onChange={onChange}
              name="email"
              value={data.email}
              error={error.email}
              type="text"
              label="Email"
              required
            />
            <hr />
            <Field
              onChange={onChange}
              name="addressLine1"
              value={data.addressLine1}
              error={error.addressLine1}
              type="text"
              label="Address Line 1"
              required
            />
            <Field
              onChange={onChange}
              name="addressLine2"
              value={data.addressLine2}
              error={error.addressLine2}
              type="text"
              label="Address Line 2"
            />
            <Field
              onChange={onChange}
              name="addressLine3"
              value={data.addressLine3}
              error={error.addressLine3}
              type="text"
              label="Address Line 3"
            />
            <div className="grid">
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="town"
                  value={data.town}
                  error={error.town}
                  type="text"
                  label="Town"
                  required
                />
              </div>
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="county"
                  value={data.county}
                  error={error.county}
                  type="text"
                  label="County"
                  required
                />
              </div>
            </div>
            <Field
              onChange={onChange}
              name="postcode"
              value={data.postcode}
              error={error.postcode}
              type="text"
              label="Postcode"
            />
            <hr />
            <div className="grid">
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="hearAbout"
                  value={data.hearAbout}
                  error={error.hearAbout}
                  type="select"
                  label="Where did you hear about JTC?"
                  options={[
                    { text: '--- Please Select ---', value: '' },
                    ...referred.map(optionMap),
                  ]}
                  required
                />
              </div>
              <div className="grid__item tablet-one-half">
                <Field
                  onChange={onChange}
                  name="hearAboutOther"
                  value={data.hearAboutOther}
                  error={error.hearAboutOther}
                  type="text"
                  label="If Other please state"
                />
              </div>
            </div>

            <hr />

            <small className="form__legal">
              By submitting your details, you agree to the use of your data by
              Leicester Tigers in accordance with our{' '}
              <a href="/legal" target="_blank" rel="noopener">
                Privacy Policy
              </a>
              . We will use your data to personalise and improve your experience
              on our platforms and provide only information, products and
              services from Leicester Tigers and our Junior Tigers Club Partners
              that are relevant and age appropriate for the Junior Tigers Club
              including a regular newsletter.
            </small>

            <Field
              name="newsletterPromotionsConsentGiven"
              type="checkbox"
              onChange={onChange}
              options={[
                {
                  text: (
                    <span>
                      Yes, I’d like to receive emails from Leicester Tigers
                      including news, promotional information and messages from
                      our partners.
                    </span>
                  ),
                  value: 'newsletterPromotionsConsentGiven',
                  required: true,
                },
              ]}
            />

            {waiting ? (
              <LoadingSpinner isSpinning width={20} height={20} />
            ) : (
              <button type="submit" className="button button--primary">
                Submit
              </button>
            )}
          </form>
        )}
      </div>
    </div>
  )
}

JuniorTigersRegister.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  waiting: PropTypes.bool,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.object,
  apiUrl: PropTypes.string,
}

export default JuniorTigersRegister
