import React from 'react'
import PropTypes from 'prop-types'

const Option = ({ option }) => {
  const { text, value, disabled, squad } = option

  return (
    <option data-squad={squad} value={value || text} disabled={disabled}>
      {text}
    </option>
  )
}

Option.propTypes = {
  option: PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    disabled: PropTypes.bool,
    squad: PropTypes.any,
  }),
}

export default Option
