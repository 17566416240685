import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Plx from 'react-plx'

import versionPath from '../../../../../../site/lib/version-path'
import Slide from '../Slide'

const Shirt = ({ config, isChange, plxEnabled }) => {
  const leftText = isChange ? 'Our Club' : 'New'
  const rightText = isChange ? 'Our Community' : 'Stripes'
  const img = isChange ? 'change-shirt.gif' : 'home-shirt.gif'
  const PlxComponent = plxEnabled ? Plx : 'div'
  return (
    <Slide
      config={config}
      backgroundType="svg"
      backgroundImage={isChange ? 'StripesHorizontal' : 'StripesVertical'}
      backgroundColor={isChange ? 'blue' : 'green'}
      isCentered
      id={isChange ? 'changeshirt' : 'homeshirt'}
      plxEnabled={plxEnabled}
    >
      <div className={classNames('ns-shirt', isChange && 'ns-shirt--change')}>
        <PlxComponent
          parallaxData={isChange ? config.fg.changeleft : config.fg.homeleft}
          className="ns-shirt__text ns-shirt__text--left"
        >
          <div>{leftText}</div>
        </PlxComponent>
        <PlxComponent
          parallaxData={isChange ? config.fg.changeshirt : config.fg.homeshirt}
        >
          <img src={versionPath(`/assets/img/content/new-stripes/${img}`)} />
        </PlxComponent>
        <PlxComponent
          parallaxData={isChange ? config.fg.changeright : config.fg.homeright}
          className="ns-shirt__text ns-shirt__text--right"
        >
          <div>{rightText}</div>
        </PlxComponent>
      </div>
      <div
        className="ns-shirt__footer"
        dangerouslySetInnerHTML={{
          __html: isChange
            ? 'OUR CLUB, OUR CITY, FOR EVERYONE'
            : 'For a new history of success',
        }}
      />
    </Slide>
  )
}

Shirt.propTypes = {
  config: PropTypes.object,
  isChange: PropTypes.bool,
  plxEnabled: PropTypes.bool,
}

export default Shirt
