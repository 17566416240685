import React from 'react'
import PropTypes from 'prop-types'
import Field from '../../primitive/field/component/Field'

const EmailSignup = ({
  successMessage,
  errorMessage,
  error = {},
  onChange,
  onSubmit,
}) => (
  <div className="email-signup">
    <div className="grid">
      <div className="grid__item desktop-one-quarter">
        <p className="email-signup__text">
          sign up to <span>the roar</span>
        </p>
        <p className="email-signup__text email-signup__text--small">
          For exclusive twice weekly news and offers from Leicester Tigers!
        </p>
      </div>
      <div className="grid__item desktop-three-quarters">
        {successMessage && Object.keys(error).length === 0 ? (
          <p className="email-signup__message">{successMessage}</p>
        ) : (
          <form className="form" action="" method="post" onSubmit={onSubmit}>
            {errorMessage && (
              <p className="email-signup__message email-signup__message--error">
                {errorMessage}
              </p>
            )}
            <div className="grid grid--valign-bottom">
              <div className="grid__item tablet-one-half desktop-one-quarter">
                <Field
                  name="firstName"
                  type="text"
                  label="First name"
                  required
                  showRequired={false}
                  modifiers={[ 'inverse' ]}
                  onChange={onChange}
                />
              </div>
              <div className="grid__item tablet-one-half desktop-one-quarter">
                <Field
                  name="lastName"
                  type="text"
                  label="Last name"
                  required
                  showRequired={false}
                  modifiers={[ 'inverse' ]}
                  onChange={onChange}
                />
              </div>
              <div className="grid__item tablet-one-half desktop-one-quarter">
                <Field
                  name="email"
                  type="email"
                  label="Email"
                  required
                  showRequired={false}
                  modifiers={[ 'inverse' ]}
                  onChange={onChange}
                />
              </div>
              <div className="grid__item tablet-one-half desktop-one-quarter">
                <button
                  className="button button--primary button--block"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>

            <div className="email-signup__footer">
              <small className="form__legal">
                By submitting your details, you agree to the use of your data by
                Leicester Tigers in accordance with our{' '}
                <a href="/legal" target="_blank" rel="noopener">
                  Privacy Policy
                </a>
                . We use your data to personalise and improve your experience on
                our platforms and provide information, products and services
                that are relevant to you from Leicester Tigers and our partners.
              </small>
              <Field
                name="newsletterPromotionsConsentGiven"
                type="checkbox"
                onChange={onChange}
                options={[
                  {
                    text: (
                      <span>
                        Yes, I’d like to receive emails from Leicester Tigers
                        including news, promotional information and messages
                        from our partners.
                      </span>
                    ),
                    value: 'newsletterPromotionsConsentGiven',
                    required: true,
                  },
                ]}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  </div>
)

EmailSignup.propTypes = {
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default EmailSignup
