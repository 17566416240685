import React from 'react'
import PropTypes from 'prop-types'
import Slide from '../Slide'

const Text = ({ config, plxEnabled }) => {
  return (
    <Slide
      config={config}
      backgroundType="image"
      backgroundImage="intro-bg.jpg"
      isCentered
      isSticky={false}
      isStickyTablet
      plxEnabled={plxEnabled}
    >
      <div className="ns-text">
        <h2 className="ns-text__heading">introduction</h2>
        <p>
          Leicester Tigers have been around for 140 years and never has the
          world been more challenging.
        </p>
        <p>
          The 2020/21 season will be the start of a new journey for the world,
          rugby and Tigers and we are determined that we will move forward
          setting the standard for sports and entertainment.
        </p>
        <p>
          We will welcome the 2020/21 season with a completely NEW LOOK; a
          new-look coaching team and playing squad, a new look to our retail
          operation as we introduce a new kit and designs, and bring the shop
          back in house; new blood on the board of directors to drive us
          forward; and a refreshed set of values we will all be living.
        </p>
        <p>
          Underneath the new will be the two pillars which will underpin
          everything we do – our supporters and our commitment to developing
          greatness from within.
        </p>
      </div>
    </Slide>
  )
}

Text.propTypes = {
  config: PropTypes.object,
  plxEnabled: PropTypes.bool,
}

export default Text
