import React from 'react'
import PropTypes from 'prop-types'

const Indicator = ({ text }) => <span className="indicator">{text}</span>

Indicator.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Indicator
