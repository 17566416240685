import React from 'react'

const FlagScotlandSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 600"
    width="900"
    height="600"
  >
    <rect width="100%" height="100%" fill="#0065BD" />
    <path d="M 0,0 L 900,600 M 0,600 L 900,0" stroke="#fff" strokeWidth="120" />
  </svg>
)

export default FlagScotlandSvg
