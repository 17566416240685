import React from 'react'
import ReactDOM from 'react-dom'
import Video from '../../../site/layout/component/video/component/Video'

const debug = require('debug')('poll')

const render = (containerNode, index) => {
  const props = containerNode.dataset
  const {
    videos,
    header,
    width,
    position,
    background,
    playlist,
    provider,
    layout,
    limit,
  } = props
  return ReactDOM.render(
    <Video
      key={index}
      header={header}
      width={width}
      position={position}
      backgroundShape={background}
      isPlaylist={!!playlist}
      provider={provider}
      layout={layout}
      limit={parseInt(limit, 10)}
      videos={JSON.parse(videos)}
    />,
    containerNode
  )
}

const initReact = () => {
  debug('Looking for Video Playlists')
  document
    .querySelectorAll('.js-video-playlist-container')
    .forEach((containerNode, index) => {
      render(containerNode, index)
    })
}

const init = () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', initReact, false)
  document.addEventListener('app:NewDOMContent', initReact, false)
}

export { init, initReact }
