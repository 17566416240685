import React from 'react'
import ReactDOM from 'react-dom'
import EmailSignup from '../../../site/layout/component/email-signup/component/EmailSignup'
import createFormSubmissionContainer from '../../lib/form-submission'
const debug = require('debug')('email-signup')
const schemata = require('@clocklimited/schemata')
const schema = schemata({
  name: 'Email signup schema',
  properties: {
    firstName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    email: {
      type: String,
    },
    newsletterPromotionsConsentGiven: {
      type: Boolean,
    },
  },
})
const FormSubmissionContainer = createFormSubmissionContainer({
  Component: EmailSignup,
  schema: schema,
  apiUrl: '/api/email-signup',
  errorMessage: 'Error. Please check your details and try again.',
  successMessage: 'Thank you for signing up to the roar.',
})

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('email-signup')
    if (!appNode) {
      return debug('No rugby camp finder root found. Skipping.')
    }
    ReactDOM.render(<FormSubmissionContainer />, appNode)
  })
}
