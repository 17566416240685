import delegate from 'delegate'
const debug = require('debug')('Toggler')

export default (sl, window) => {
  debug('Init')
  delegate(window.document.body, '.js-toggle-switch', 'click', (e) => {
    const toggle = e.delegateTarget
    toggle.classList.toggle('is-active')
    const context = toggle.getAttribute('data-toggle')
    const query = context
      ? `.js-toggle-content[data-toggle=${context}]`
      : '.js-toggle-content'
    toggle.parentNode.querySelector(query).classList.toggle('is-hidden')
  })
}
