import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Plx from 'react-plx'

import versionPath from '../../../../../../site/lib/version-path'

import Svgs from '../../../../layout/component/primitive/svgs/component/Svgs'

const Slide = ({
  backgroundColor,
  backgroundType,
  backgroundImage,
  config,
  children,
  isSticky,
  isStickyTablet,
  isStickyDesktop,
  isCentered,
  isMobileCenteredColumn,
  isFullHeight,
  isFullHeightTablet,
  isFullHeightDesktop,
  noHeightLimit,
  isBorderless,
  isShort,
  isShortMobile,
  isBackgroundExtended,
  id,
  plxEnabled,
  className,
}) => {
  const SvgComponent = backgroundType === 'svg' && Svgs[backgroundImage]
  const plxBackgroundConfigMap = {
    svg: config && config.bg[backgroundImage],
    image: config && config.bg.image,
  }
  const plxBackgroundConfig = plxBackgroundConfigMap[backgroundType]
  const PlxComponent = plxEnabled ? Plx : 'div'

  return (
    <React.Fragment>
      <div className={classNames('ns-slide__plx-wrapper', isBackgroundExtended && `ns-slide__background--full`, className)}>
        <div
          className={classNames(
            'ns-slide__background',
            isBackgroundExtended && `ns-slide__background--full`,
            backgroundColor && `ns-slide__background--${backgroundColor}`
          )}
        >
          <div
            style={{
              backgroundImage: `${
                backgroundType === 'image'
                  ? `url(${versionPath(
                      `/assets/img/content/new-stripes/${backgroundImage}`
                    )})`
                  : 'none'
              }`,
            }}
            className={classNames(
              backgroundType === 'image' && 'ns-slide__background-image',
              backgroundType === 'svg' && 'ns-slide__background-svg',
              backgroundType === 'svg' &&
                backgroundColor === 'blue' &&
                'ns-slide__background-svg--large'
            )}
            // parallaxData={config && plxBackgroundConfig}
          >
            {backgroundType === 'svg' && <SvgComponent />}
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'ns-slide',
          isBorderless && 'ns-slide--borderless',
          isSticky && 'ns-slide--sticky',
          isStickyTablet && 'ns-slide--sticky-tablet',
          isStickyDesktop && 'ns-slide--sticky-desktop',
          isCentered && 'ns-slide--centered',
          isMobileCenteredColumn && 'ns-slide--centered-mobile-column',
          isFullHeight && 'ns-slide--full-height',
          isFullHeightTablet && 'ns-slide--full-height-tablet',
          isFullHeightDesktop && 'ns-slide--full-height-desktop',
          noHeightLimit && 'ns-slide--no-height-limit',
          isShort && 'ns-slide--short',
          isShortMobile && 'ns-slide--short-mobile',
          className
        )}
        id={id}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

Slide.defaultProps = {
  isSticky: true,
  isFullHeight: true,
}

Slide.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundType: PropTypes.string,
  children: PropTypes.node,
  config: PropTypes.object,
  isSticky: PropTypes.bool,
  isStickyTablet: PropTypes.bool,
  isStickyDesktop: PropTypes.bool,
  isCentered: PropTypes.bool,
  isFullHeight: PropTypes.bool,
  isFullHeightTablet: PropTypes.bool,
  isFullHeightDesktop: PropTypes.bool,
  isBorderless: PropTypes.bool,
  isShort: PropTypes.bool,
  isShortMobile: PropTypes.bool,
  id: PropTypes.string,
  plxEnabled: PropTypes.bool,
  isBackgroundExtended: PropTypes.bool,
  className: PropTypes.string,
  isMobileCenteredColumn: PropTypes.bool,
  noHeightLimit: PropTypes.bool
}

export default Slide
